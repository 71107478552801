/*eslint-disable*/
import cloneDeep from 'lodash/cloneDeep';
import Cookies from 'js-cookie';

export function clone(data) {
	return cloneDeep(data);
}

export function getUrlKey(name) { // 获取url 参数
	return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20')) || null;
}

export function getLangContent(obj) { // 获取语言对应的内容
	const keyConfig = {
		zh: 'cn',
		en: 'en',
		ja: 'jp',
	};
	const lang = Cookies.get('language') || 'zh';

	return obj ? obj[keyConfig[lang]] : '';
}

export default {
	clone,
	getUrlKey,
	getLangContent,
};
