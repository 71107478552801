<template>
	<div class="page-footer">
		<div class="page-content-box">
			<el-row>
				<el-col :span="24" class="hidden-md-and-up">
				<div class="logo-area logo-area-mobile">
					<img src="/imgStatic/footer.png" alt="" @click="handleToPage('/')">
				</div>
				</el-col>
				<el-col :xs="12" :sm="12" :md="5" v-for="(item, index) in menuData" :key="item.id" :class="getLastMenuClass(index)">
				<div class="footer-col-dom">
					<h5>{{ getLangContent(item.name) }}</h5>
					<ul>
					<li
						v-for="itemChild in item.children"
						:key="itemChild.id"
					>
						<a :href="itemChild.url" v-text="getLangContent(itemChild.name)" :target="itemChild.target === 'new' ? '_blank' : '_self'"></a>
					</li>
					</ul>
				</div>
				</el-col>
				<el-col :span="9" class="hidden-sm-and-down">
				<div class="logo-area">
					<img src="/imgStatic/footer.png" alt="" @click="handleToPage('/')">
				</div>
				</el-col>
			</el-row>
		<div class="bottom">
			<el-row>
				<el-col :xs="24" :sm="24" :md="11">
					<div class="footer-col-dom">
					<ul>
						<li>
						<a href="https://www.johnsoncontrols.com/legal/terms" target="_blank">{{ $t("footerData.lawTitle") }}</a>
						</li>
						<li>
						<a href="https://www.johnsoncontrols.com/legal/privacy" target="_blank">{{ $t("footerData.privacyTitle") }}</a>
						</li>
					</ul>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="13">
					<div class="footer-col-dom copyright">
					<div class="footer-col-dom-text hidden-md-and-up">
						<div class="copyright-bold">{{ $t("footerData.copyright1") }}</div>
						{{ $t("footerData.copyright2") }}
					</div>
					<ul class="footer-col-dom-text hidden-sm-and-down">
						<li>
						<font class="copyright-bold">{{ $t("footerData.copyright1") }}</font>
						{{ $t("footerData.copyright2") }}
						</li>
					</ul>
					</div>
				</el-col>
			</el-row>
      <el-row>
        <div class="footer-col-dom record-dom">
          <ul>
						<li>
            <a href="http://beian.miit.gov.cn" target="_blank">{{ $t("footerData.recordTxt") }}</a>
						</li>
            <li>
            <a href="http://www.beian.gov.cn" target="_blank">{{ $t("footerData.policeRecordTxt") }}</a>
						</li>
					</ul>
        </div>
      </el-row>
		</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'Footer',
    props: {
        msg: String,
    },
    data() {
		return {
			showLangMenu: false,
		};
    },
	created() {},
    methods: {
		handleToPage(path) {
			if (this.$route.path === path) {
				return;
			}

			window.location.href = path;
		},
		handleShowQrCode() {
			this.$emit('showQrCodeDialog');
		},
    },
    computed: {
      webMode() {
        return this.$store.state.webMode;
      },
      menuConfig() {
        return this.$store.state.menuConfig;
      },
      menuData() {
        const menuData = [];
        (this.menuConfig || []).forEach(item => {
          if (item.position === 'footer') {
            menuData.push(item);
          }
        });

        return menuData;
      },
      getLastMenuClass() {
        return item => {
          let className = '';
          if (this.menuData % 2 !== 0 && item === this.menuData.length - 1) {
            className = 'last-menu-dom';
          }

          return className;
        };
      },
    },
    watch: {},
};
</script>

<style scoped lang="scss">
.page-footer {
  width: 100%;
  padding: 100px 0 60px;
  background-color: #F7F8F8;
  overflow: hidden;
  .page-content-box {
    padding-left: 12px;
    padding-right: 12px;
    .logo-area {
      display: flex;
      margin-bottom: 40px;
      img {
        margin-left: auto;
        width: 136px;
        cursor: pointer;
      }
    }
    .logo-area-mobile {
      img {
        margin-left: 0;
      }
    }
    .footer-col-dom {
      // padding: 0 12px;
      h5 {
        font-size: 12px;
        font-weight: bold;
        color: #333333;
        line-height: 12px;
        letter-spacing: .05em;
        text-transform: uppercase;
        padding-bottom: 0.5em;
      }
      .medium-h5 {
        padding-bottom: 0;
      }
      ul {
        padding-left: 0;
        list-style: none;
        li {
          display: flex;
          align-items: center;
          margin: 17px 0;
          text-decoration: none;
          color: #7f7f7f;
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;
          // text-transform: uppercase;
          -webkit-transition: color .3s ease-in-out 0ms;
          transition: color .3s ease-in-out 0ms;
          a {
            text-decoration: none;
            color: #7f7f7f;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            -webkit-transition: color .3s ease-in-out 0ms;
            transition: color .3s ease-in-out 0ms;
          }
          a:hover {
            color: #000;
          }
          .wx-share {
            margin-left: 17px;
            cursor: pointer;
          }
          .wx-share:hover {
            opacity: .6;
          }
        }
      }
      .footer-col-dom-text {
        text-decoration: none;
        color: #7f7f7f;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        -webkit-transition: color .3s ease-in-out 0ms;
        transition: color .3s ease-in-out 0ms;
        .copyright-bold {
          font-weight: bolder;
        }
      }
    }
    .bottom {
      ul {
        li {
          display: inline-block;
          margin-right: 60px;
        }
      }
      .copyright {
        padding-right: 0;
        ul {
          display: flex;
          justify-content: flex-end;
          li {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.app-pc {
  .page-footer {
    padding: 40px 0 !important;
    .footer-col-dom {
      h5 {
        font-size: 16px !important;
        line-height: 16px !important;
        padding-bottom: 0 !important;
      }
      ul {
        li {
          margin-top: 13px !important;
          margin-bottom: 13px !important;
          font-size: 16px !important;
          a {
            font-size: 16px !important;
          }
        }
      }
    }
    .footer-col-dom-text {
      font-size: 16px !important;
    }
    .bottom {
      ul {
        margin: 0;
      }
      .copyright {
        ul {
          li {
            font-size: 14px !important;
          }
        }
      }
      .record-dom {
        text-align: right;
        li {
          margin-top: 0 !important;
          margin-right: 0 !important;
          margin-left: 30px;
          a {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
.app-mobile {
  .page-footer {
    padding: 40px 0;
    .last-menu-dom {
      width: 100%;
    }
    .bottom {
      ul {
        li {
          margin-right: 30px;
        }
      }
      .record-dom {
        li {
          margin-top: 0 !important;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
  .app-en {
    .app-pc {
      .footer-address-content {
        line-height: 16px !important;
      }
    }
    .app-mobile {
      .footer-address-content {
        line-height: 17px !important;
      }
      .copyright {
        div {
          line-height: 17px !important;
        }
      }
    }
  }
  .app-ja {
    .app-pc {
      .footer-address-content {
        line-height: 16px !important;
      }
    }
  }
</style>
