import axios from '@/lib/axios';

/**
 * 获取导航栏
 */
export function getNavAll(params) {
	return axios.get('/portal/nav/get', params);
}

/**
 * 获取广告位
 */
 export function getAd(params) {
	return axios.get('/portal/ad/get', params);
}

/**
 * 获取广告位
 */
 export function getSeries(params) {
	return axios.get('/portal/series/get', params);
}

/**
 * 获取新闻目录
 */
 export function getCategory(params) {
	return axios.get('/portal/category/get', params);
}

/**
 * 获取新闻列表
 */
 export function getNewsList(params) {
	return axios.get('/portal/article/page', params);
}

/**
 * 获取新闻详情
 */
 export function getNewsDetail(params) {
	return axios.get('/portal/article/detail', params);
}

/**
 * 获取页面碎片列表
 */
 export function getPageFragment(params) {
	return axios.get('/portal/page/fragment', params);
}

/**
 * 获取下载文件列表
 */
 export function getDownloadList(params) {
	return axios.get('/portal/download/list', params);
}

/**
 * 提交访客留言
 */
 export function feedSubmit(params) {
	return axios.post('/portal/feed/submit', params);
}
