import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/home',
		name: 'Home',
		meta: {
			title: '首页',
			showHeader: true,
			showFooter: true,
		},
		component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
	},
	{
		path: '/news',
		name: 'News',
		meta: {
			title: '新闻资讯',
			showHeader: true,
			showFooter: true,
		},
		component: () => import(/* webpackChunkName: "News" */ '../views/News.vue'),
	},
	{
		path: '/newsDetail',
		name: 'NewsDetail',
		meta: {
			title: '新闻资讯',
			showHeader: true,
			showFooter: true,
		},
		component: () => import(/* webpackChunkName: "NewsDetail" */ '../views/NewsDetail.vue'),
	},
	{
		path: '/contract',
		name: 'Contract',
		meta: {
			title: '联系我们',
			showHeader: true,
			showFooter: true,
		},
		component: () => import(/* webpackChunkName: "Contract" */ '../views/Contract.vue'),
	},
	{
		path: '/products',
		name: 'Products',
		meta: {
			title: '产品展示',
			showHeader: true,
			showFooter: true,
		},
		component: () => import(/* webpackChunkName: "Products" */ '../views/Products.vue'),
	},
	{
		path: '/about',
		name: 'About',
		meta: {
			title: '关于我们',
			showHeader: true,
			showFooter: true,
		},
		component: () => import(/* webpackChunkName: "About" */ '../views/About.vue'),
	},
	{
		path: '*',
		redirect: '/home',
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
