/* eslint-disable*/
export default {
    config: {
        langZh: "中文",
        langEn: "English",
        langJa: "日本語",
    },
    titleData: {
        Home: "Johnson Controls-Hitachi Wanbao Compressor (Guangzhou) Co., Ltd.",
        News: "News",
        NewsDetail: "News",
        Contract: "Contact Us",
        About: "About Us",
        Products: "Products",
    },
    headerData: {
        headerCompany: "Johnson Controls-Hitachi Wanbao Compressor (Guangzhou) Co., Ltd.",
        headerCompanyMobile: "Johnson Controls-Hitachi Wanbao Compressor (Guangzhou) Co., Ltd.",
        menuData: {
            home: 'Home',
            about: 'About Us',
            news: 'News',
            product: 'Products',
            productSelect: 'Product Selector',
            contract: 'Contact Us',
        },
        qrCodeTips: "Scan and pay attention to us.",
    },
    footerData: {
        navTitle: "MAIN MENU",
        contractTitle: "CONTACT US",
        contractPhone: "Tel: 020-87861868",
        contractFax: "Fax: 020-87861808",
        contractWx: "WeChat: ",
        addressTitle: "COMPANY ADDRESS",
        addressContent: "Addr: No. 1086, East Road, Chengao Avenue, Conghua District, Guangzhou, Guangdong Province",
        productLinkTitle: "GLOBAL PRODUCT LINK",
        productLinkText: "JCH Compressor",
        lawTitle: "LEGAL & COOKIES POLICIES",
        privacyTitle: "PRIVACY POLICY",
        copyright1: '© COPYRIGHT 2022. ALL RIGHTS RESERVED.',
        copyright2: "All rights reserved by Johnson Controls Hitachi Wanbao compressor (Guangzhou) Co., Ltd.",
        recordTxt: "粤ICP备2021111563号-2",
        policeRecordTxt: "公安网备：44011702000639",
    },
    pageDom: {
        moreBtn: 'Learn more',
        checkMoreBtn: 'Learn more',
        submitMessageBtn: 'Submit',
        checkNowBtn: 'Visit Now',
        downloadBtn: 'Download',
        contractBtn: 'Contact Us',
    },
    homeData: {
        slogan: "cooling & heating",
        aboutIntroData: {
            title: 'About Us',
            content: 'Johnson Controls Hitachi Compressor (Guangzhou) Co., Ltd. (formerly Guangzhou Hitachi Compressor Co., Ltd.) is a large compressor manufacturing base established in Conghua, Guangzhou. Founded in September 2003, it is funded by Johnson Controls Hitachi Air Conditioning Service (Hong Kong) Co., Ltd., Guangzhou Wanbao Group Co., Ltd. and Taiwan Hitachi Johnson Controls Co., Ltd. The business mainly engages in producing scroll compressors for refrigeration and air conditioning. The business scope primarily includes manufacture, sales and after-sales service of scroll compressors. The company has more than 900 employees and 0.9 million sets/year production capacity.',
        },
        newsIntroData: {
            title: 'Our News',
            content: 'Learn more news',
        },
        productIntroData: {
            title: 'Application fields of core products',
            content: 'Our products are widely used in many application fields. Our refrigerant covers R410A, R407C, R404A, R32, R134a, R1234yf, R454B, R513A, R448A, etc.',
        },
        contractIntroData: {
            title: 'Contact Us',
            content1: 'We help you to succeed anytime, anywhere!',
            content2: 'For more product and sales advice, please get in touch with us.',
        }
    },
    newsData: {
        pageTitle: 'All News',
        allCategoryName: 'All',
    },
    newsDetailData: {
        moreNewsTitle: 'More News',
    },
    contractData: {
        messageTitle: 'Guest Message',
        messageTitleDesc: 'Please leave a message, we will contact you as soon as possible.',
        agreementText1: 'I have read and accepted the ',
        agreementText2: 'LEGAL & COOKIES POLICIES',
        agreementText3: 'and',
        agreementText4: 'PRIVACY POLICY',
        agreementText5: 'of JCH',
        surnamePlaceholder: 'Surname*',
        namePlaceholder: 'First name*',
        emailPlaceholder: 'Email*',
        phonePlaceholder: 'Contact Number*',
        companyPlaceholder: 'Company Name*',
        addressPlaceholder: 'Contact Address*',
        requirementDescPlaceholder: 'Requirement Description',
        opinionPlaceholder: 'Please input opinions here',
        contractInfoTitle: 'Contact Information',
        addressContent: "Address：No. 1086, East Road, Chengao Avenue, Conghua District, Guangzhou, Guangdong Province",
        contractPhone: "Tel: 020-87861360",
        contractFax: "Fax: 020-87861358",
        contractWx: "WeChat:",
    },
    productsData: {
        productSelectData: {
            title: 'Hitachi Compressor Selection Tool',
            content: 'A selection tool is indispensable for project managers in their day-to-day work. For #HitachiScroll Compressors, a brand new cloud-based web application named Hitachi Compressor Selector has been launched (offering three languages: Chinese, English and Japanese). It is designed to help customers find and select the products they need, with ease.',
            introIconTitle1: 'New Software',
            introIconTitle2: 'Scroll Compressors',
            introIconTitle3: 'Improved Customer Experience',
            introIconTitle4: 'Web-base Tool',
        },
        productIntroData: {
            title: 'Application fields of core products',
            content1: 'Our products are widely used in many application fields: Air Conditioning, Chiller & Heat Pump, Heat Pump Dryer, Data Center & Energy Storage, Transportation Air Conditioning, Refrigeration.',
            content2: 'Refrigerant covers R410A, R407C, R404A, R32, R134a, R1234yf, R454B, R513A, R448A, etc.',
        },
        productNameRule: {
            title: 'Nomenclature',
        },
        productDoc: {
            title: 'Technical Documentation',
        },
        refrigerantType: 'Refrigerant type:',
    },
    aboutData: {
        introData: {
            title: 'Company Profile',
            content1: 'Johnson Controls Hitachi Compressor (Guangzhou) Co., Ltd. (formerly Guangzhou Hitachi Compressor Co., Ltd.) is a large compressor manufacturing base established in Conghua, Guangzhou. Founded in September 2003, it is funded by Johnson Controls Hitachi Air Conditioning Service (Hong Kong) Co., Ltd., Guangzhou Wanbao Group Co., Ltd. and Taiwan Hitachi Johnson Controls Co., Ltd. The business mainly engages in producing scroll compressors for refrigeration and air conditioning. The business scope primarily includes manufacture, sales and after-sales service of scroll compressors. The company has more than 900 employees and 0.9 million sets/year production capacity.',
            content2: 'Our products are widely used in many application fields: Air Conditioning, Chiller & Heat Pump, Heat Pump Dryer, Data Center & Energy Storage, Transportation Air Conditioning, Refrigeration. Our refrigerant covers R410A, R407C, R404A, R32, R134a, R1234yf, R454B, R513A, R448A, etc.',
            content3: 'Our primary customers consist of well-known domestic air-conditioning enterprises such as Midea, Gree, Hisense, Haier and Carrier; global air-conditioning enterprises of JCH and Hitachi Group, as well as domestic and overseas customers in India, Egypt, Argentina, etc.',
            content4: 'We have the insight to gather technology, provide targeted solutions helping customers to succeed anytime and anywhere. Take "values first" as the tenet, abide by our solutions, social participation, business operation mode and commitment to protecting people and the environment. We shoulder the responsibility of building a safe, comfortable and sustainable world. We thrive on working with you to create a better tomorrow.',
            content5: 'Our vision: Working towards a safe, comfortable and sustainable world.',
            content6: 'Our mission: Help our customers succeed anytime, anywhere.',
            content7: 'Our values: Integrity first, purpose-oriented, customer-driven, focus on the future. We are a team!',
        },
        marketingNetworkData: {
            title: 'Marketing Network',
            customerTitle: 'Major Customer Groups',
            customerContent1: 'Domestic Customers',
            customerContent2: 'Gree, Midea, Hisense Hitachi, Haier, mcwell, York, Trane, Carrier, Oxfam, Changhong, TCL, Vic, Fennicks, Shenling, Oko, Invec, Jialitu, Imicon, Guoxiang, Dinghan, Meleko, etc.',
            customerContent3: 'Overseas Customers',
            customerContent4: "Our company have branches in Japan, the Middle East, Europe, North America, Singapore, and Gabor in foreign countries. In addition to Johnson Controls' international and internal customers of Johnson Controls Hitachi, we provide products and services to global customers within the scope of the international policy of Johnson Controls.",
            saleContractTitle: 'Sales Contact',
            saleContractContent1: 'Address: No. 1086, east of Chengao Avenue, Conghua District, Guangzhou, Guangdong Provinc',
            saleContractContent2: 'Tel: 020-87861360',
            saleContractContent3: 'Fax: 020-8786135',
        },
        qualityData: {
            title: 'Quality Assurance',
            qualityTextTitle1: 'Machining line',
            qualityTextContent1: 'Advanced high-precision manufacturing equipment from Japan, Taiwan, the United States, Germany, etc. are adopted to ensure the stability of each production and manufacturing process quality. We thrive on realizing production intelligence and operation standardization in an all-around way.',
            qualityTextTitle2: 'Motor',
            qualityTextContent2: 'Utilize motor manufacturing machine imported from Japan, and work as Cobot-assisted to realize automated production of stator and rotor, steadily and efficiently ensure good and consistent quality.',
            qualityTextTitle3: 'Casing',
            qualityTextContent3: `Maintain steady production by employing world-leading energy storage welding machine and intermediate frequency inverter welding machine.
            Achieve fully automated production of upper and lower casing by using KUKA robot, remarkably enhance quality consistency and productivity.
            Casing production automation rate further improves as multi-station cobot rolls out.`,
            qualityTextTitle4: 'Assembly line',
            qualityTextContent4: 'A complete set of automatic assembly production lines imported from Japan is adopted to standardize the quality of each compressor leaving the factory through online synchronous detection.',
            qualityTextTitle5: 'Process detection',
            qualityTextContent5: "It adopts the international advanced three coordinate measuring instrument, roundness instrument, profilometer and other precision instruments. These are utilized to sample and check complex shapes' dimensions, geometrical tolerances, and roughness. Their accuracy reaches the micron level and corrects various measuring tools' geometrical and dimensional errors.",
            qualityTextTitle6: 'Finished product testing',
            qualityTextContent6: 'Adopt international top-level performance, noise and life testing equipment for sampling inspection and reliability confirmation of finished products.',
            qualityIntroText: `Johnson Controls Hitachi Wanbao compressor (Guangzhou) Co., Ltd. implements Johnson Controls JCMS manufacturing management system. We adhere to Hitachi's strict quality management concept as our quality policy: "customer-centric, continuous quality improvement, management technology innovation, and striving for the first-class industry". Thoroughly implement the comprehensive management system certification based on the three systems of ISO9001 quality management, ISO14001 environmental management, as well as ISO45001 occupational health and safety management.`,
        },
        qualityCertificationData: {
            title: 'Quality Certification',
            content: 'The compressor produced by Johnson Controls Hitachi Wanbao compressor (Guangzhou) Co., Ltd. has been certified as follows: CB certification, CE-LVD certification, ce-ped certification, CQC certification, CRAA certification and UL certification.',
        },
        companyTableInfoData: {
            lineTitle1: 'Corporate name',
            lineContent1: 'Johnson Controls Hitachi Wanbao Compressor (Guangzhou) Co., Ltd. ',
            lineTitle2: 'Main business',
            lineContent2: 'R&D, production, sales and after-sales service of scroll compressor',
            lineTitle3: 'Registered capital',
            lineContent3: 'US $17.8 million',
            lineTitle4: 'Date of establishment',
            lineContent4: 'September 2003 ',
            lineTitle5: 'Address',
            lineContent5: 'No. 1086, East Road, Chengao Avenue, Conghua District, Guangzhou, Guangdong',
            lineTitle6: 'Number of employees',
            lineContent6: '900',
        }
    },
    testData: {
        homeData: {
            adList: {
                id1: {
                    title: 'About Us',
					desc: 'Located in Guangzhou, we are a leading manufacturing base of Hitachi scroll compressors in China.',
                },
                id2: {
                    title: 'Our Marketing Network',
					desc: 'The majority of customers are located all over the world (China, India, Egypt, Argentina, etc.), who are well-known enterprises locally and abroad.',
                },
                id3: {
                    title: 'Our Products',
					desc: 'Our products are widely used in many application fields: Air Conditioning, Chiller & Heat Pump, Heat Pump Dryer, Data Center & Energy Storage, Transportation Air Conditioning, Refrigeration.',
                },
                id4: {
                    title: 'Our Core Technology',
					desc: 'Our product design has several technical patents: dynamic oil balance technology, optimization of the asymmetric vortex, and high-speed characteristics…',
                },
            },
            newsList: {
                id1: {
                    title: 'JCH-GC won "Top Ten Supply Chain Products" and "Extreme Intelligence Award".',
					date: 'Sep. 25, 2021',
                },
                id2: {
                    title: 'Hitachi compressor innovation DD110 came into the market.',
					date: 'April 7, 2021',
                },
                id3: {
                    title: 'JCH-GC products won the honor of "Innovative products".',
					date: 'April 5, 2021',
                },
            },
            productList: {
                id1: {
                    category: 'APPLICATIONS',
					name: 'Air Conditioning',
                },
                id2: {
                    category: 'APPLICATIONS',
					name: 'Chiller & Heat Pump',
                },
                id3: {
                    category: 'APPLICATIONS',
					name: 'Heat Pump Dryer',
                },
                id4: {
                    category: 'APPLICATIONS',
					name: 'Data Center & Energy Storage',
                },
                id5: {
                    category: 'APPLICATIONS',
					name: 'Transportation Air Conditioning',
                },
                id6: {
                    category: 'APPLICATIONS',
					name: 'Refrigeration',
                },
            },
        },
        productsData: {
            productDocList: {
                id1: {
                    title: 'For Residential & Commercial Application',
                },
                id2: {
                    title: 'For Heatpump Application',
                },
                id3: {
                    title: 'For Transport Application',
                },
                id4: {
                    title: 'For Refrigeration Application',
                },
                id5: {
                    title: 'For Datacenter Application',
                },
                id6: {
                    title: 'For Dryer Application',
                },
                id7: {
                    title: 'For Energy Storage System Application',
                },
                id8: {
                    title: 'Inverter',
                }
            }
        },
        aboutData: {
            certificateList: {
                id1: {
                    title: 'ISO 9001 Certification',
                },
                id2: {
                    title: 'ISO 14001 Certification',
                },
                id3: {
                    title: 'ISO 45001 Certification',
                },
                id4: {
                    title: 'PED M.D certificate',
                },
                id5: {
                    title: 'CQC  certificate',
                },
                id6: {
                    title: 'CB certificate',
                },
                id7: {
                    title: 'UL certificate',
                },
                id8: {
                    title: 'CE-LVD certificate',
                },
                id9: {
                    title: 'CRAA Product certification',
                },
                id10: {
                    title: 'UKCA certificate',
                },
                id11: {
                    title: 'EAC certificate',
                },
            }
        },
        newsData: {
            newsList: {
                id1: {
                    title: 'JCH-GC won "Extreme intelligence Award" and "Top ten supply chain products".',
					date: 'SEP 25, 2021',
                    desc: `JCH-GC won two awards at the China HVAC industry development summit and China's "Smart manufacturing for cooling and heating" award ceremony.`,
                },
                id2: {
                    title: 'Hitachi compressor innovation DD110 comes into the market.',
					date: 'APR 7, 2021',
                    desc: 'Hitachi compressor innovative product dd110khdg DC inverter scroll compressor on the market.',
                },
                id3: {
                    title: 'JCH-GC products won the honor of "Innovative products".',
					date: 'NOV 5, 2021',
                    desc: 'JCH-GC product DD110KHDG-D1K2 low-temperature inverter heat pump scroll compressor has won awards for excellent performance and reliability.',
                },
                id4: {
                    title: 'JCH-GC won the highest award of "strategic mutual trust Award" of Hisense Home Appliances.',
					date: 'DEC 18, 2020',
                    desc: 'JCH-GC won the highest award of "Strategic mutual trust Award" at the Hisense Home Appliance core supplier summit of "Gathering the future to create infinity".',
                },
                id5: {
                    title: 'JCH-GC won the "excellent supplier award" of Midea HVAC and building division in 2020.',
					date: 'DEC 9, 2020',
                    desc: 'Midea HVAC and building division successfully held a supplier conference in Shunde, Foshan, Guangdong. At the conference, Johnson Controls Hitachi Wanbao Compressor (Guangzhou) Co., Ltd. was awarded "excellent supplier". ',
                },
                id6: {
                    title: `JCH-GC won Haier's "innovation leading Award".`,
					date: 'NOV 15, 2020',
                    desc: `JCH-GC won the "Innovation leading Award" in Haier's annual supplier review.`,
                },
            },
            categoryList: {
                id1: {
                    name: 'All',
                },
                id2: {
                    name: 'Industry News',
                },
                id3: {
                    name: 'Company Dynamics',
                },
                id4: {
                    name: 'Marketing Activities',
                },
            },
        },
        newsDetailData: {
            newsDetailListData: {
                id1: {
                    title: 'JCH-GC won "extreme intelligence Award" and "top ten supply chain products"',
                    date: 'SEP 25, 2021',
                    detail: `
                        <p>JCH-GC won the "Extreme intelligence Award" and "Top ten supply chain products" - green core set sail, cold and warm core smart manufacturing.</p>
                        <p>On 25th September 2021, the China HVAC industry development summit and China's "Intelligent manufacturing for cooling and heating" award ceremony came to a successful conclusion in Hangzhou. The summit was themed: "Cold and warm smart manufacturing · new sail". It brought together representatives from government departments, upstream and downstream enterprises of the industrial chain, experts and scholars from scientific research institutes, industry media and other relevant personnel to witness this grand event in the HVAC industry.</p>
                        <p>At the award ceremony of "Cold, warm and intelligent manufacturing" in China, JCH-GC won the<font style="color:#C3002F">"Extremely Intelligent Award"</font>with its excellent performance of scientific and technological hard power, as well as innovation driving force! At the same time, the scroll compressor of low-temperature inverter heat pump won the<font style="color:#C3002F">"Top 10 Supply Chain Product Award"</font>!</p>
                    `,
                },
                id2: {
                    title: 'Hitachi compressor innovation DD110 comes into market',
                    date: 'APR 7, 2021',
                    detail: `
                        <p>On 7th April 2021, the launch ceremony of DD110KHDG DC inverter scroll compressor, an innovative product of Hitachi compressor, was successfully held at the China Refrigeration Exhibition. At the launch ceremony, Hitachi revealed this breakthrough product to the public for the first time and demonstrated relevant breakthrough technological achievements.</p>
                        <p>Li Xiaohu, vice president and Secretary-General of China Refrigeration Society, Shi Wenxing, Professor of Tsinghua University, Hu Xianghua, R&D director of York Guangzhou Air Conditioning and Refrigeration Equipment Co., Ltd., Hao Zhi, general manager of Johnson Controls Hitachi Wanbao Compressor (Guangzhou) Co., Ltd., Xia Youhui, deputy general manager of Johnson Controls Hitachi Wanbao Compressor (Guangzhou) Co., Ltd., amongst other experts and scholars attended the press conference, fully affirmed the breakthrough of Hitachi's innovative technology.</p>
                        <p>"Hitachi of technology" has been the creed of the Hitachi brand for hundreds of years. From the launch of the world's first commercial scroll compressor to the multi-field application of full-range products, the Hitachi compressor always adheres to the concept of technology-driven progress. The release of this innovative product fully shows Hitachi compressor's determination to promote industry technology development with practical actions. Constantly push through the old and bring forth the new.</p>
                        <p>(See the details: https://mp.weixin.qq.com/s/nYFM4dOzkZAulfDfd8ATWw)</p>
                    `,
                },
                id3: {
                    title: 'JCH-GC products won the honor of "Innovative products"',
                    date: 'NOV 5, 2021',
                    detail: `
                        <p>The "Innovative products" of the 2021 China Refrigeration Exhibition had 112 product declarations from 52 enterprises. The four product categories include household and commercial air conditioning heat pump equipment, cold chain equipment, special air conditioning heat pump equipment and other equipment. The review was divided into two rounds: preliminary review and final review.
                        After a formal review by the organizing committee, the application materials were reviewed by the expert group.  A total of 36 experts (43 person-time) participated in the review.</p>
                        <p>Our product DD110KHDG-D1K2 low-temperature inverter heat pump scroll compressor has won the honour of an "Innovative product" for its excellent performance and reliability.</p>
                    `,
                },
                id4: {
                    title: 'JCH-GC won the highest award of "Strategic mutual trust Award" of Hisense home appliances',
                    date: 'DEC 18, 2020',
                    detail: `
                        <p>On 18t December 2020, Hisense Home Appliances held a Global Core Supplier summit themed "Gathering the future and creating infinity". Johnson Controls Hitachi Wanbao Compressor (Guangzhou) Co., Ltd. won the highest award of Hisense Home Appliances "Strategic mutual trust Award" with superior product technology. In the future, Johnson Controls Hitachi Wanbao Compressor (Guangzhou) Co., Ltd. will continue to cooperate with Hisense Home Appliances, jointly study and explore the development direction of the industry and market. We strive to innovate, providing society with more energy-saving and efficient, excellent products.</p>
                    `,
                },
                id5: {
                    title: 'JCH-GC won the "Excellent supplier award" of Midea HVAC via building division in 2020',
                    date: 'DEC 9, 2020',
                    detail: `
                        <p>On 9th December 2020, Midea HVAC successfully held a supplier conference via the building division in Shunde, Foshan, Guangdong. At the meeting, Johnson Controls Hitachi Wanbao Compressor (Guangzhou) Co., Ltd. was awarded the "excellent supplier" award. Johnson Controls Hitachi Wanbao Compressor (Guangzhou) Co., Ltd. and Midea HVAC have cooperated closely for many years and witnessed projects' growth together. In the future, Johnson Controls Hitachi Wanbao Compressor (Guangzhou) Co., Ltd. will go hand in hand with Midea HVAC to create more brilliance.</p>
                    `,
                },
                id6: {
                    title: `JCH-GC won Haier's "Innovation leading Award"`,
                    date: 'NOV 15, 2020',
                    detail: `
                        <p>In November 2020, Haier conducted a supplier review. Johnson Controls Hitachi Wanbao Compressor (Guangzhou) Co., Ltd. won Haier's top prize of the "Innovation Leadership Award" for its excellent product strength. It is a great honour to be recognized by Haier. For a long time, Johnson Controls Hitachi Wanbao Compressor (Guangzhou) Co., Ltd. has firmly implemented the concept of resource sharing, complementary advantages and mutual benefit with Haier. In the future, Johnson Controls Hitachi Wanbao Compressor (Guangzhou) Co., Ltd. will work with Haier to promote the energy efficiency level of industrial products and lead new development.</p>
                    `,
                },
            }
        },
        codeProductData: {
            id1: {
                title: 'Commercial Air Conditioning',
                contentTitle: 'VRF & PAC',
                content: `With years of experience in reliability design, Hitachi's high-efficiency and high-speed variable speed scroll compressors are able to provide air-conditioning systems with solutions with ultra-high energy efficiency, higher cooling (heating) capacity and wider operating range,  which can also  meet the trend of energy saving and carbon reduction.`,
                refrigerantContent: 'Refrigerant type:R32, R410A, R22',
                charact: {
                    id1: {
                        content: 'Hitachi high-side DC INV gas injection scroll compressor',
                    },
                    id2: {
                        content: 'High reliability and quality',
                    },
                    id3: {
                        content: 'High efficiency, optimized rating efficiency (COP), optimized comprehensive  efficiency (APF)',
                    },
                    id4: {
                        content: 'Dynamic oil balance patented design to achieve free connection',
                    },
                    id5: {
                        content: 'Low sound & vibration',
                    },
                    id6: {
                        content: 'Excellent gas injection technology, expanded operating range, and great increase in heating capacity at low ambient temperature',
                    },
                    id7: {
                        content: 'Oil supplied by positive displacement pump, better oil supply structure, OCR (oil discharge rate) reduced, compressor reliability and heat exchange efficiency of the unit improved',
                    },
                    id8: {
                        content: 'Ultra-wide speed range, rapid and strong cooling or heating, providing users with better comfortability',
                    },
                }
            },
            id2: {
                title: 'Chiller & Heat Pump',
                contentTitle: 'Air conditioning, Space heating, Hot water',
                content: 'Hitachi DC INV gas injection scroll compressors, with several patented technologies, are widely used in chiller and heat pump systems, creating ideal, comfortable, low-carbon and energy-saving living spaces for people.',
                refrigerantContent: 'Refrigerant type:R454B, R32, R410A',
                charact: {
                    id1: {
                        content: 'Hitachi high-side DC INV gas injection scroll compressor',
                    },
                    id2: {
                        content: 'High reliability and quality',
                    },
                    id3: {
                        content: 'High efficiency, optimized comprehensive efficiency (APF/IPLV)',
                    },
                    id4: {
                        content: 'Low sound level and vibration',
                    },
                    id5: {
                        content: ' Excellent gas injection technology, expanded operating range, and great increase in heating capacity at low ambient temperature',
                    },
                    id6: {
                        content: 'Ultra-high pressure ratio design (up to 14.3) secures the heating requirements at low ambient temperature. For example, the unit can still operate safely and reliably at an ambient temperature of -35°C.',
                    },
                    id7: {
                        content: 'Ultra-wide speed range, rapid and strong cooling or heating, providing users with better comfortability',
                    },
                    id8: {
                        content: `Single compressor's capacity can meet 65KW unit.`,
                    },
                }
            },
            id3: {
                title: 'Heat Pump Dryer',
                contentTitle: 'Drying',
                content: `Hitachi's original large capacity DC INV gas injection scroll compressor with ultra-high drying temperature has made outstanding contributions to carbon reduction  in the heat pump drying industry.
                Its main application covers industrial and agricultural high temperature heat pump fields, such as grain, crop and tobacco leaf drying, and high temperature hot water and drying dehumidification fields such as electroplating and printing processes.`,
                refrigerantContent: 'Refrigerant type:R410A, R134a',
                charact: {
                    id1: {
                        content: 'Hitachi high-side DC INV gas injection scroll compressor',
                    },
                    id2: {
                        content: 'High efficiency, high reliability, high quality',
                    },
                    id3: {
                        content: 'Low sound & vibration',
                    },
                    id4: {
                        content: 'Excellent gas injection technology, expanded operating range, and great increase in heating capacity at low ambient temperature, ensuring drying effect does not decline',
                    },
                    id5: {
                        content: 'The inverter design enables precise temperature control, and improves the quality of dried products',
                    },
                    id6: {
                        content: 'The ultra-high pressure ratio design (up to 19.8) meets the drying requirements under all climate conditions throughout the year.',
                    },
                    id7: {
                        content: 'Wide operating envelope, maximum condensing temperature as high as 85℃, meeting the drying needs of industry and agriculture',
                    },
                }
            },
            id4: {
                title: 'Data Center & Energy Storage',
                contentTitle: 'IDC (Internet Data Center),  ESS(Energy Storage System)',
                content: 'Hitachi DC INV scroll compressor meets the requirements on energy saving and high reliability of high efficiency computer room and data center air conditioners and energy storage air conditioners, enabling units to achieve ultra-high energy efficiency, providing green, efficient, stable and reliable solutions for data centers.',
                refrigerantContent: 'Refrigerant type:R410A, R134a, R1234yf',
                charact: {
                    id1: {
                        content: 'Hitachi high-side DC INV gas injection scroll compressor',
                    },
                    id2: {
                        content: 'High efficiency, high reliability, high quality',
                    },
                    id3: {
                        content: 'Low sound & vibration',
                    },
                    id4: {
                        content: 'Ultra-wide pressure ratio operating envelope (pressure ratio from 1.1 to 8.0) to meet the efficient operation requirements of refrigeration under various seasonal conditions',
                    },
                    id5: {
                        content: 'Oil supplied by positive displacement pump, better oil supply structure, OCR (oil discharge rate) reduced, compressor reliability and heat exchange efficiency of the unit improved',
                    },
                    id6: {
                        content: 'Threaded connection design for suction and exhaust ports instead of welding',
                    },
                    id7: {
                        content: 'Electrical junction box designed with IP67 protection level enables the unit to meet the high protection requirements in various applications.',
                    },
                    id8: {
                        content: 'Ultra-wide speed range, rapid and strong cooling to quickly reduce the temperature of the battery module and prolong its service life',
                    },
                }
            },
            id5: {
                title: 'Transportation Air Conditioning',
                contentTitle: 'Air conditioning of RV & EV',
                content: 'The compressor particularly designed for transportation air conditioners has technical advantages such as high efficiency and reliability, low installation height (177mm) and light weight. It can be applied to transportation air-conditionings such as high-speed trains, railways, metros, electric-buses etc., and is widely used in many projects in more than 30 countries and regions around the world.',
                refrigerantContent: 'Refrigerant type:R513A, R410A, R407C, R134a',
                charact: {
                    id1: {
                        content: 'Hitachi high-side DC INV gas injection scroll compressor',
                    },
                    id2: {
                        content: 'High efficiency, high reliability, high quality',
                    },
                    id3: {
                        content: 'Low sound & vibration',
                    },
                    id4: {
                        content: 'Compact size, light weight, more space-efficient and energy-saving',
                    },
                    id5: {
                        content: 'Electrical junction box designed with IP67 protection level enables the unit to meet the high protection requirements in various applications.',
                    },
                }
            },
            id6: {
                title: 'Refrigeration',
                contentTitle: 'Showcase & Refrigerated warehouses',
                content: `Refrigeration overall solutions. Hitachi high-quality scroll compressor offers higher efficiency, outstanding durability and unparalleled reliability, provides solutions for the whole cold chain managing low temperature preservation throughout the entire process of production, transportation, sales, storage etc. `,
                refrigerantContent: 'Refrigerant type:R448A, R449A, R410A, R404A, R22',
                charact: {
                    id1: {
                        content: 'Hitachi high-side DC INV gas injection scroll compressor',
                    },
                    id2: {
                        content: 'High efficiency, high reliability, high quality',
                    },
                    id3: {
                        content: 'Dynamic oil balance patented design to achieve free connection',
                    },
                    id4: {
                        content: 'Low sound & vibration',
                    },
                    id5: {
                        content: 'Excellent liquid injection technology, expanded operating envelope to meet the application requirements of ultra-low evaporation temperature',
                    },
                    id6: {
                        content: `Standard oil sight glass and oil discharge pipe design, monitoring compressor's oil level and quality in real time, convenient for operation and maintenance`,
                    },
                }
            }
        }
    },
};
