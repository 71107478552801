/* eslint-disable*/
export default {
    config: {
        langZh: "中文",
        langEn: "English",
        langJa: "日本語",
    },
    titleData: {
        Home: "江森自動制御日立万宝コンプレッサー（広州）有限公司",
        News: "ニュース",
        NewsDetail: "ニュース",
        Contract: "連絡します",
        About: "私たちについて",
        Products: "商品の展示",
    },
    headerData: {
        headerCompany: "江森自動制御日立万宝コンプレッサー（広州）有限公司",
        headerCompanyMobile: "江森自動制御日立万宝コンプレッサー",
        menuData: {
            home: '最初のページ',
            about: '私たちについて',
            news: 'ニュース',
            product: '商品の展示',
            productSelect: '製品の選択',
            contract: '連絡します',
        },
        qrCodeTips: "スキャンして、私たちに注目してください。",
    },
    footerData: {
        navTitle: "下のナビゲーション",
        contractTitle: "連絡先",
        contractPhone: "電話番号：020-87861868",
        contractFax: "ファックス：020-87861808",
        contractWx: "WeChat：",
        addressTitle: "会社の住所",
        addressContent: "住所：広東省広州市従化城鳌大道東路1086号",
        productLinkTitle: "グローバル製品リンク",
        productLinkText: "江森自動制御日立圧縮機",
        lawTitle: "法律声明",
        privacyTitle: "プライバシー条項",
        copyright1: '© COPYRIGHT 2022. ALL RIGHTS RESERVED.',
        copyright2: "江森自動制御日立万宝コンプレッサー（広州）有限公司の 著作権所有",
        recordTxt: "粤ICP备2021111563号-2",
        policeRecordTxt: "公安网备：44011702000639",
    },
    pageDom: {
        moreBtn: '詳細',
        checkMoreBtn: '詳細',
        submitMessageBtn: '送信',
        checkNowBtn: '今すぐ表示',
        downloadBtn: 'ダウンロード',
        contractBtn: '連絡します',
    },
    homeData: {
        slogan: "cooling & heating",
        aboutIntroData: {
            title: '私たちについて',
            content: '江森自動制御日立万宝コンプレッサー（広州）有限公司（元広州日立コンプレッサー有限公司）は、江森自控日立エアコンサービス（香港）有限公司、広州万宝グループ有限公司、台湾日立江森自制株式会社三方が出資して広州に従化した大型圧縮機生産基地である。2003年9月に建てられました。主に冷凍とエアコン用の渦圧縮機の生産に従事しています。経営範囲は主に渦圧縮機の製造、販売、アフターサービスを含みます。今は900人以上の従業員がいます。90万台/年の生産能力を持っています。',
        },
        newsIntroData: {
            title: 'ニュース',
            content: 'ニュース情報の詳細',
        },
        productIntroData: {
            title: 'コア製品の応用分野',
            content: '私たちの製品の応用は広く、多くの応用分野に及んでいます。冷媒は、R 410 A、R 407 C、R 404 A、R 32、R 134 a、R 1234 yf、R 454 B、R 513 A、R 448 A等を覆う。',
        },
        contractIntroData: {
            title: 'お問い合わせ',
            content1: '私たちはいつでもどこでもあなたの成功に協力します。',
            content2: '詳細な製品および販売に関するお問い合わせは、お問い合わせください。',
        }
    },
    newsData: {
        pageTitle: 'ニュース情報',
        allCategoryName: 'すべて',
    },
    newsDetailData: {
        moreNewsTitle: 'その他のニュース',
    },
    contractData: {
        messageTitle: 'お問い合わせ',
        messageTitleDesc: '伝言をお願いします。できるだけ早く連絡します。',
        agreementText1: 'JCHに関する',
        agreementText2: '法律政策',
        agreementText3: 'と',
        agreementText4: 'プライバシー条項',
        agreementText5: 'を読み、受け入れました。',
        surnamePlaceholder: '姓*',
        namePlaceholder: '名前*',
        emailPlaceholder: 'メールアドレス*',
        phonePlaceholder: '電話番号*',
        companyPlaceholder: '会社名*',
        addressPlaceholder: '連絡先*',
        requirementDescPlaceholder: '需要説明',
        opinionPlaceholder: '意見',
        contractInfoTitle: '連絡先',
        addressContent: "住所：広東省広州市従化城鳌大道東路1086号",
        contractPhone: "電話番号：020-87861868",
        contractFax: "ファックス：020-87861808",
        contractWx: "WeChat：",
    },
    productsData: {
        productSelectData: {
            title: '日立スクロール圧縮機オンライン選択ツール',
            content: 'プロジェクトマネージャの日常的な作業では、選択ツールは欠かせません。スクロール圧縮機については、クラウドメンテナンスの更新に基づいたオンライン型「日立スクロール圧縮機」オプションソフト「Hitachi Compressor Selector」(中国語、英語、日本語の3言語選択)が誕生し、お客様が必要な製品を簡単に探して選択できるようにすることを目的としています。',
            introIconTitle1: '新しいツール',
            introIconTitle2: 'スクロール圧縮機',
            introIconTitle3: '体験アップグレード',
            introIconTitle4: 'オンラインバージョン',
        },
        productIntroData: {
            title: 'コア製品の応用分野',
            content1: '当社の製品の応用は広範囲で、多くの分野に関連しています。商用エアコン、モジュール機とヒートポンプ、ヒートポンプ乾燥機、データセンターとエネルギー貯蔵システム、輸送エアコン、冷凍冷蔵。',
            content2: '冷媒は、R 410 A、R 407 C、R 404 A、R 32、R 134 a、R 1234 yf、R 454 B、R 513 A、R 448 A等が含まれています。',
        },
        productNameRule: {
            title: '製品仕様型番命名規則',
        },
        productDoc: {
            title: '技術文書',
        },
        refrigerantType: '冷媒種類：',
    },
    aboutData: {
        introData: {
            title: '会社概要',
            content1: '江森自動制御日立万宝コンプレッサー（広州）有限公司（元広州日立コンプレッサー有限公司）は、江森自控日立エアコンサービス（香港）有限公司、広州万宝グループ有限公司、台湾日立江森自制株式会社三方が出資して、広州従化にある大型圧縮機生産基地です。2003年9月に建てられました。主に冷凍とエアコン用の渦圧縮機の生産に従事しています。経営範囲は主に渦圧縮機の製造、販売、アフターサービスを含みます。今は900人以上の従業員がいます。90万台/年の生産能力を持っています。',
            content2: '私たちの製品の応用は広範囲で、多くの応用分野に関連しています。商用エアコン、モジュール機とヒートポンプ、ヒートポンプ乾燥機、データセンターとエネルギー貯蔵システム、輸送エアコンと冷凍冷蔵。冷媒は、R 410 A、R 407 C、R 404 A、R 32、R 134 a、R1234 yf、R 454 B、R 513 A、R 448 A等を覆う。',
            content3: '弊社の主な取引先は格力、海信、美的、ハイアールなどの国内の有名なエアコン企業で、日立グループ、JCHの全世界のエアコン企業、インド、エジプト、アルゼンチンなどの国内外の取引先も含まれています。',
            content4: '私達は洞察力を持って技術を集めて、的確な解決案を提供しています。いつでもどこでも取引先に協力して成功に向かっていきます。「価値観第一」を信条として、私達の解決案を遵守し、社会参加、商業運営方式と人と環境を守るという承諾を持って、安全、快適、持続可能な発展の世界を建設する責任を負い、皆様と手を携えてより良い明日を創造します。',
            content5: '私達のビジョン：安全、快適、持続可能な発展の世界。',
            content6: '私達の使命：いつでもどこでも取引先に協力して成功に向かいます。',
            content7: '私達の価値観：誠信第一、目的案内、お客様駆動、未来に関心を持つ、チーム！',
        },
        marketingNetworkData: {
            title: 'マーケティングネットワーク',
            customerTitle: '私たちのグループはお客様グループを必要とします',
            customerContent1: '国内のお客様',
            customerContent2: 'グリコ、アメリカの、海信日立、ハイアール、マクビル、ヨーク、トリン、カイリー、オークス、長虹、TCL、ヴィック、ヴィック、ヴィックニッツ、シン菱、ヨーロッパ科、英ヴィック、カムリ図、依米康、国祥、鼎漢、美楽柯など',
            customerContent3: '海外のお客様',
            customerContent4: '海外では、当社は日本、中東、ヨーロッパ、北米、シンガポールなどに支店を設けております。江森自動制御国際と江森自動制御日立内部の顧客以外に、江森自動制御国際政策の許容範囲内で、世界のお客様に製品とサービスを提供しています。',
            saleContractTitle: '販売ホットライン',
            saleContractContent1: '住所：広州市従化区城鳌大道東路1086号',
            saleContractContent2: '電話：020-87861360',
            saleContractContent3: 'ファックス：020-87861358',
        },
        qualityData: {
            title: '品質保証',
            qualityTextTitle1: 'マシン加工ライン',
            qualityTextContent1: '日本、台湾、米国、ドイツなどの先進的な高精度の製造設備を採用して、生産製造工程ごとの品質の安定性を確保し、全面的に生産知能化、操作規範化を実現します。',
            qualityTextTitle2: 'モータライン',
            qualityTextContent2: '日本から輸入したモーター製造設備を採用し、Cobot（協力ロボット）に補助し、固定回転子の自動化生産を実現し、安定かつ効率的に製品品質と一致性を保証する。',
            qualityTextTitle3: 'シェルライン',
            qualityTextContent3: '世界をリードするエネルギー貯蔵式溶接機と中周波数インバータ溶接機を使用して、生産が安定している。KUKAロボットを使用して上下シェルの全自動化生産を実現し、品質安定性と生産効率を大幅に向上させた。Cobot(コラボレーションロボット)マルチステーションの展開に伴い,ハウジング自動化率はさらに向上した。',
            qualityTextTitle4: '組立ライン',
            qualityTextContent4: '日本から輸入した全自動化セットの生産ラインを採用して、オンライン同時検査によって、工場から出荷するコンプレッサーの品質の均一性を保証します。',
            qualityTextTitle5: 'プロセス検出',
            qualityTextContent5: '国際先進水準の三座標測定器、円度計、輪郭計などの精密機器を用いて、標本検査に用いられる複雑な形状の寸法、寸法公差、粗さなどの精度はミクロン級に達しており、各種の計量器の形状誤差、寸法誤差を補正する。',
            qualityTextTitle6: '製品検査',
            qualityTextContent6: '国際的なリードレベルの性能、騒音、寿命検査装置を採用して、製品サンプル検査と信頼性確認に用います。',
            qualityIntroText: '江森自控日立万宝コンプレッサー（広州）有限公司は江森自控JCMS製造管理システムを推進し、日立の厳格な品質管理の理念を受けて、「顧客を中心に、品質改善を継続し、管理技術革新を行い、業界一流を目指します」を品質方針としています。ISO 9001品質管理、ISO 14001環境管理、ISO45001職業健康安全管理の3つの体系を全面的に推進し、総合管理システムの認証を取得しました。',
        },
        qualityCertificationData: {
            title: '品質認証',
            content: '江森自動制御日立万宝コンプレッサー（広州）有限公司が生産したコンプレッサーは以下の認証を行いました。CB認証、CE-LVD認証、CE-PIED認証、CQC認証、CRAA認証、UL認証などです。',
        },
        companyTableInfoData: {
            lineTitle1: '会社の概要',
            lineContent1: '江森自動制御日立万宝コンプレッサー（広州）有限公司',
            lineTitle2: '主な業務',
            lineContent2: 'スクロール圧縮機の研究開発、生産、販売、アフターサービス',
            lineTitle3: '資本金',
            lineContent3: '1780万ドルです ',
            lineTitle4: '設立日',
            lineContent4: '2003年9月',
            lineTitle5: '住&nbsp;&nbsp;&nbsp;所',
            lineContent5: '広州市従化区城鳌大道東路1086号',
            lineTitle6: '従業員の人数',
            lineContent6: '900',
        }
    },
    testData: {
        homeData: {
            adList: {
                id1: {
                    title: '私たちについて',
					desc: '当社は広州に位置し、国内業界をリードする日立スクロール圧縮機の生産基地です...',
                },
                id2: {
                    title: 'デルのマーケティングネットワーク',
					desc: '当社の主なお客様は国内外(中国、インド、エジプト、アルゼンチンなど)に分布しており、お客様は国内外の有名な企業です。',
                },
                id3: {
                    title: 'デルの製品',
					desc: '当社の製品の応用は広い範囲で、多くの応用分野に関連しています:商用エアコン、モジュール機とヒートポンプ、ヒートポンプ乾燥機、データセンターとエネルギー貯蔵システム、輸送エアコン、冷凍冷蔵',
                },
                id4: {
                    title: 'デルのコアテクノロジ',
					desc: 'コア技術 :  弊社の製品は多くの技術特許を持っています。動的オイルバランス技術を持っています。非対称渦線、高回転速度特性を最適化します…',
                },
            },
            newsList: {
                id1: {
                    title: 'JCH-GCは「極智賞」と「十大サプライチェーン製品」の二賞を受賞した',
					date: '2021年9月25日',
                },
                id2: {
                    title: '日立コンプレッサ革新製品DD 110発売',
					date: '2021年4月7日',
                },
                id3: {
                    title: 'JCH-GC製品は「革新製品」の栄誉を獲得した',
					date: '2021年4月5日',
                },
            },
            productList: {
                id1: {
                    category: '応用分野',
					name: '商用エアコン',
                },
                id2: {
                    category: '応用分野',
					name: 'モジュールとヒートポンプ',
                },
                id3: {
                    category: '応用分野',
					name: 'ヒートポンプ乾燥機',
                },
                id4: {
                    category: '応用分野',
					name: 'データセンターとエネルギー貯蔵システム',
                },
                id5: {
                    category: '応用分野',
					name: 'ゆそうエアコン',
                },
                id6: {
                    category: '応用分野',
					name: 'れいとうれいきゃく',
                },
            },
        },
        productsData: {
            productDocList: {
                id1: {
                    title: '住宅又は商用エアコン専用製品',
                },
                id2: {
                    title: 'ヒートポンプ専用製品',
                },
                id3: {
                    title: '運送エアコン専用製品',
                },
                id4: {
                    title: '冷凍冷蔵専用製品',
                },
                id5: {
                    title: '機械室エアコン専用製品',
                },
                id6: {
                    title: 'ヒートポンプ乾燥専用製品',
                },
                id7: {
                    title: 'エネルギー貯蔵専用製品',
                },
                id8: {
                    title: 'インバータ製品',
                }
            }
        },
        aboutData: {
            certificateList: {
                id1: {
                    title: 'ISO 9001 認証証明書',
                },
                id2: {
                    title: 'ISO 14001 認証証明書',
                },
                id3: {
                    title: 'ISO 45001 認証証明書',
                },
                id4: {
                    title: 'PED M.D 証明書',
                },
                id5: {
                    title: 'CQC  証明書',
                },
                id6: {
                    title: 'CB 証明書',
                },
                id7: {
                    title: 'UL 証明書',
                },
                id8: {
                    title: 'CE-LVD 証明書',
                },
                id9: {
                    title: 'CRAA 製品認証証明書',
                },
                id10: {
                    title: 'UKCA 証明書',
                },
                id11: {
                    title: 'EAC 証明書',
                },
            }
        },
        newsData: {
            newsList: {
                id1: {
                    title: 'JCH-GCは「極智賞」と「十大サプライチェーン製品」の二賞を受賞した。',
					date: '2021年9月25日',
                    desc: 'JCH-GCは中国暖通エアコン産業発展サミット及び中国「冷暖智造」授賞式で二賞を受賞した。',
                },
                id2: {
                    title: '日立コンプレッサ革新製品DD 110を新発売。',
					date: '2021年4月7日',
                    desc: '日立コンプレッサ革新製品DD 110 KHDG直流周波数変換スクロールコンプレッサ発売。',
                },
                id3: {
                    title: 'JCH-GC製品は「革新製品」の栄誉を獲得した',
					date: '2021年10月5日',
                    desc: 'JCH-GC製品DD 110 KHDG-D 1 K 2低温周波数変換ヒートポンプスクロール圧縮機は卓越した性能と信頼性で栄誉を獲得した。',
                },
                id4: {
                    title: 'JCH-GCは海信家電「戦略的相互信頼賞」最高賞を受賞',
					date: '2020年12月18日',
                    desc: '「未来を集めて無限を創造する」海信家電コアサプライヤーサミット、JCH-GCは「戦略的相互信頼賞」最高賞を受賞。',
                },
                id5: {
                    title: 'JCH-GCは米暖通とビル事業部2020年「優秀サプライヤー賞」を受賞',
					date: '2020年12月9日',
                    desc: '広東省仏山順徳市で開かれた米暖通とビル事業部のサプライヤー総会で、江森自動制御日立万宝コンプレッサー（広州）有限公司が「優秀サプライヤー」賞を授与された。',
                },
                id6: {
                    title: 'JCH-GCハイアール「イノベーションリード賞」受賞',
					date: '2020年11月15日',
                    desc: 'JCH-GCはハイアールサプライヤーの年度審査で「革新リード賞」を受賞した。',
                },
            },
            categoryList: {
                id1: {
                    name: 'すべて',
                },
                id2: {
                    name: '業界内の声',
                },
                id3: {
                    name: '企業の動向',
                },
                id4: {
                    name: '市場活動',
                },
            },
        },
        newsDetailData: {
            newsDetailListData: {
                id1: {
                    title: 'JCH-GCは「極智賞」と「十大サプライチェーン製品」の二賞を受賞した。',
                    date: '2021年9月25日',
                    detail: `
                        <p>JCH-GCは「極智賞」と「十大サプライチェーン製品」の二賞を受賞した--緑芯新出航、冷暖芯智造</p>
                        <p>2021年9月25日夜、中国暖通エアコン産業発展サミットおよび中国の「冷暖智造」授賞式が杭州で円満に閉幕した。今回のサミットは「冷暖智造・新出航」をテーマに、政府部門、産業チェーンの上下流企業の代表、科学研究所からの専門家や学者、業界メディアなどの近人が一堂に会し、この暖通業界の盛事を目撃した。</p>
                        <p>今回の中国の「冷暖智造」授賞式で、自身の科学技術の力、革新駆動力の優秀な表現で<font style="color:#C3002F">「極智賞」</font>を受賞した。同時に低温周波数変換ヒートポンプスクロール圧縮機が<font style="color:#C3002F">「十大サプライチェーン製品賞」</font>を受賞!</p>
                    `,
                },
                id2: {
                    title: '日立コンプレッサ革新製品DD 110を新発売。',
                    date: '2021年4月7日',
                    detail: `
                        <p>2021年4月7日、日立圧縮機の革新製品DD 110 KHDG直流周波数変換スクロール圧縮機の発売発表式が中国冷凍展の現場で順調に開催された。発表式では、日立が初めて突破的な製品を公表し、突破的な技術成果を示した。</p>
                        <p>中国冷凍学会副理事長兼秘書長の李暁虎氏、清華大学教授の石文星氏、ヨーク広州空調冷凍設備有限会社研究開発総監の胡湘華氏、江森自制日立万宝圧縮機(広州)有限会社社長の郝志氏、江森自制日立万宝圧縮機(広州)有限会社副社長の夏友輝氏ら専門家と学者が出席し、日立イノベーション技術の突破を十分に肯定した。</p>
                        <p>「技術の日立」は日立ブランドが数百年にわたって発展してきた信条であり、世界初の商用スクロール圧縮機の発売から、現在までの全シリーズ製品の多分野応用まで、日立圧縮機は技術駆動の進歩の理念を一貫して受け継いでおり、今回の革新製品の発表は、日立圧縮機が常に実際の行動で業界技術の発展を推進し、絶えず新しい決意を表明した。</p>
                        <p>（詳しくは記事を参照 https://mp.weixin.qq.com/s/nYFM4dOzkZAulfDfd8ATWw ）</p>
                    `,
                },
                id3: {
                    title: 'JCH-GC製品は「革新製品」の栄誉を獲得した',
                    date: '2020年10月5日',
                    detail: `
                        <p>2021中国冷凍展の「革新製品」には52社の企業から112件の製品が申告され、製品種別は家庭用及び商用エアコンヒートポンプ設備、冷鎖設備、専用エアコンヒートポンプ設備、その他の設備の4種類をカバーしている。組織委員会の形式審査を経て、申告資料は専門家グループの審査に提出した。審査は初審と終審の2回に分けて行われ、36人(43人)の専門家を審査に招待した。</p>
                        <p>我が社の製品DD 110 KHDG-D 1 K 2低温周波数変換ヒートポンプ渦圧縮機は卓越した性能と信頼性で、「革新製品」の栄誉を獲得した。</p>
                    `,
                },
                id4: {
                    title: 'JCH-GCは海信家電「戦略的相互信頼賞」最高賞を受賞',
                    date: '2020年12月18日',
                    detail: `
                        <p>2020年12月18日、海信家電は「未来を集めて無限を創造する」をテーマとしたグローバルコアサプライヤーサミットを展開し、江森自制日立万宝圧縮機(広州)有限会社は優れた製品技術で海信家電最高賞「戦略相互信頼賞」を受賞した。未来江森自制日立万宝圧縮機(広州)有限会社は海信家電と持続的に協力し、業界と市場の発展方向を共同で研究・検討し、革新に努力し、社会により省エネ・高効率の優秀な製品を提供する。</p>
                    `,
                },
                id5: {
                    title: 'JCH-GC荣获美的暖通与楼宇事业部2020年“优秀供应商奖”',
                    date: '2020年12月9日',
                    detail: `
                        <p>2020年12月9日、米暖通とビル事業部は広東仏山順徳でサプライヤー大会を順調に開催した。会議では、江森自制日立万宝圧縮機(広州)有限会社が「優秀サプライヤー」賞を授与された。数年来、江森自制日立万宝圧縮機(広州)有限会社は美の暖通と長年にわたって緊密に協力し、一つのプロジェクトの成長を共に目撃した。将来、江森自制日立万宝圧縮機(広州)有限会社は美の暖通と手を携えて同行し、より多くの輝きを創造する。</p>
                    `,
                },
                id6: {
                    title: 'JCH-GCハイアール「イノベーションリード賞」受賞',
                    date: '2021年11月15日',
                    detail: `
                        <p>2020年11月、ハイアールはサプライヤーの審査を行い、江森自動制御日立万宝コンプレッサー（広州）有限公司は過硬な製品実力でハイアール最高賞「革新リード賞」を受賞した。これまで、江森自制日立万宝圧縮機(広州)有限会社はハイアール資源の共有、優位性の相互補完、互恵・ウィンウィンの理念をしっかりと貫徹してきました。将来、江森自動制御日立万宝コンプレッサー（広州）有限公司はハイアールと共同で業界製品のエネルギー効率レベルを推進し、新たな発展をリードする。</p>
                    `,
                },
            }
        },
        codeProductData: {
            id1: {
                title: '商用エアコン',
                contentTitle: 'マルチライン/ユニットマシン',
                content: '信頼性設計における長年の経験により、日立の高効率で高速なインバータースクロールコンプレッサーは、超高エネルギー効率、より高い冷却（加熱）容量、およびより広い動作範囲を備えたソリューションを空調システムに提供し、トレンドに対応できます。省エネと炭素削減の。',
                refrigerantContent: '冷媒種類：R32, R410A, R22',
                charact: {
                    id1: {
                        content: '高圧キャビティDC可変周波数ジェットエンタルピー増加スクロールコンプレッサー',
                    },
                    id2: {
                        content: '高信頼性と高品質',
                    },
                    id3: {
                        content: '高効率、評価点（COP）最適化、全体的なエネルギー効率（APF）最適化',
                    },
                    id4: {
                        content: 'コンプレッサーダイナミックオイルバランスの特許取得済みの設計により、自由なマルチ接続が実現します',
                    },
                    id5: {
                        content: '低騒音・低振動',
                    },
                    id6: {
                        content: '優れたジェットエンタルピー設計により、動作範囲が広がり、低い周囲温度での加熱能力が大幅に向上します',
                    },
                    id7: {
                        content: '容積式オイルポンプは、オイルを供給し、オイル供給メカニズムを最適化し、OCR（オイル排出率）を低減し、コンプレッサーの信頼性を向上させ、ユニットの熱交換効率を最適化します',
                    },
                    id8: {
                        content: '超ワイドスピードデザイン、高速で強力な冷却/加熱、より快適な環境をユーザーに提供',
                    },
                }
            },
            id2: {
                title: 'モジュール機とヒートポンプ',
                contentTitle: 'エアコン、暖房、お湯',
                content: '多くの特許技術を備えた日立DC可変周波数ジェットスクロールコンプレッサーは、モジュラーマシンやヒートポンプシステムで広く使用されており、人々にとって理想的で快適な低炭素で省エネの生活空間を作り出しています。',
                refrigerantContent: '冷媒種類：R454B, R32, R410A',
                charact: {
                    id1: {
                        content: '高圧キャビティDC可変周波数ジェットエンタルピー増加スクロールコンプレッサー',
                    },
                    id2: {
                        content: '高信頼性と高品質',
                    },
                    id3: {
                        content: '高効率、統合エネルギー効率（APF / IPLV）の最適化',
                    },
                    id4: {
                        content: '低騒音・低振動',
                    },
                    id5: {
                        content: '優れたジェットエンタルピー設計により、動作範囲が広がり、低い周囲温度での加熱能力が大幅に向上します',
                    },
                    id6: {
                        content: '超高圧比設計（最大14.3）により、低い周囲温度での加熱要件が保証されます。 たとえば、ユニットは-35°Cの周囲温度でも安全かつ確実に動作できます。',
                    },
                    id7: {
                        content: '超ワイドスピードデザイン、高速で強力な冷却/加熱、より快適な環境をユーザーに提供',
                    },
                    id8: {
                        content: '1台のコンプレッサーで65KWユニットに対応',
                    },
                }
            },
            id3: {
                title: 'ヒートポンプ乾燥機',
                contentTitle: '乾燥',
                content: '日立独自の超高乾燥温度の大型DC可変周波数ジェットスクロールコンプレッサーは、ヒートポンプ乾燥業界における「炭素削減」の実践に大きく貢献しています。 これは主に、産業および農業の高温ヒートポンプ分野（穀物、作物乾燥、タバコの葉の乾燥を含む）、ならびに電気めっき、印刷およびその他のプロセスにおける高温温水および乾燥および除湿で使用されます。',
                refrigerantContent: '冷媒種類：R410A, R134a',
                charact: {
                    id1: {
                        content: '高圧キャビティDC可変周波数ジェットエンタルピー増加スクロールコンプレッサー',
                    },
                    id2: {
                        content: '高效、高信頼性と高品質',
                    },
                    id3: {
                        content: '低騒音・低振動',
                    },
                    id4: {
                        content: '優れたジェットエンタルピー増加設計、動作範囲の拡大、および低い周囲温度での強力な加熱により、乾燥効果が低下しないようにします',
                    },
                    id5: {
                        content: '周波数変換設計、正確な温度制御、乾燥アイテムの品質の向上',
                    },
                    id6: {
                        content: '超高圧比設計（最大19.8）は、年間を通じて気候条件の下での乾燥要件を満たします。',
                    },
                    id7: {
                        content: '広い動作範囲、最高凝縮温度は85℃と高く、産業や農業の乾燥ニーズに対応します',
                    },
                }
            },
            id4: {
                title: 'データセンターとエネルギー貯蔵システム',
                contentTitle: 'コンピュータルーム、データセンターエアコン、エネルギー貯蔵エアコン',
                content: 'Hitachi DCインバータースクロールコンプレッサーは、高効率のコンピューター室、データセンター、省エネで信頼性の高いエネルギー貯蔵エアコンの要件を満たし、ユニットの超高エネルギー効率の動作を実現し、環境に優しく、効率的で、安定した、信頼性の高いものを提供しますデータセンター向けのソリューション。',
                refrigerantContent: '冷媒種類：R410A, R134a, R1234yf',
                charact: {
                    id1: {
                        content: '高圧キャビティDCインバータースクロールコンプレッサー',
                    },
                    id2: {
                        content: '高效、高信頼性と高品質',
                    },
                    id3: {
                        content: '低騒音・低振動',
                    },
                    id4: {
                        content: 'さまざまな季節条件下での効率的な冷凍操作の要件を満たす超幅圧比運転範囲（圧比1.1-8.0）',
                    },
                    id5: {
                        content: '容積式オイルポンプは、オイルを供給し、オイル供給メカニズムを最適化し、OCR（オイル排出率）を低減し、コンプレッサーの信頼性を向上させ、ユニットの熱交換効率を最適化します',
                    },
                    id6: {
                        content: '吸込・排気管ねじ山接続設計、溶接作業不要',
                    },
                    id7: {
                        content: 'IP67保護等級の電気接続箱の設計により、ユニットはさまざまなアプリケーションの高い保護要件に適合します。',
                    },
                    id8: {
                        content: 'バッテリーモジュールの温度をすばやく下げ、バッテリーの寿命を延ばすための超ワイド回転速度、高速で強力な冷却',
                    },
                }
            },
            id5: {
                title: '輸送エアコン',
                contentTitle: '鉄道車両用エアコン、乗用車用エアコン',
                content: '輸送用エアコン用に特別に設計された日立スクロールコンプレッサーは、高速鉄道、都市鉄道、地下鉄、電気バス、その他の輸送用空気に適した、高効率、高信頼性、低い設置高さ（高さ177mm）、軽量特性などの技術的利点があります。世界30以上の国と地域の多くのプロジェクトで広く使用されているコンディショニングフィールド。',
                refrigerantContent: '冷媒種類：R513A, R410A, R407C, R134a',
                charact: {
                    id1: {
                        content: '高圧キャビティDCインバータースクロールコンプレッサー',
                    },
                    id2: {
                        content: '高效、高信頼性と高品質',
                    },
                    id3: {
                        content: '低騒音・低振動',
                    },
                    id4: {
                        content: 'コンパクトなサイズ、軽量、より多くのスペース節約、より多くの省エネ',
                    },
                    id5: {
                        content: 'IP67保護等級の電気接続箱の設計により、ユニットはさまざまなアプリケーションの高い保護要件に適合します。',
                    },
                }
            },
            id6: {
                title: '冷凍冷蔵',
                contentTitle: '陳列棚、冷蔵',
                content: '冷凍冷蔵全体ソリューション。日立の高品質スクロール圧縮機は、より高い効率、優れた耐久性、比類のない信頼性を備えています。冷鎖の生産、輸送、販売、貯蔵などの全過程の低温保存を実現するために解決策を提供する。',
                refrigerantContent: '冷媒種類：R448A, R449A, R410A, R404A, R22',
                charact: {
                    id1: {
                        content: '高圧キャビティDC可変周波数液体注入エンタルピースクロールコンプレッサー',
                    },
                    id2: {
                        content: '高效、高信頼性と高品質',
                    },
                    id3: {
                        content: 'コンプレッサーダイナミックオイルバランスの特許取得済みの設計により、コンプレッサーの自由なマルチ接続が実現します',
                    },
                    id4: {
                        content: '低騒音・低振動',
                    },
                    id5: {
                        content: '優れたスプレー液体エンタルピーは設計を向上させ、動作範囲を広げ、ユニットの超低蒸発温度のアプリケーション要件を満たします',
                    },
                    id6: {
                        content: '標準のオイルミラーとオイル排出パイプの設計、コンプレッサーの作動オイルレベルとオイルのリアルタイム監視、操作とメンテナンスが簡単',
                    },
                }
            }
        }
    },
};
