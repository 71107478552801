<template>
	<div id="app" class="appClass" :key="updateKey">
		<div id="pageScala" v-html="pageScala"></div>
		<div id="header" v-show="$route.meta.showHeader">
			<Header @showQrCodeDialog="handleShowQrCode" />
		</div>
		<router-view/>
		<div id="footer" v-show="$route.meta.showFooter">
			<Footer @showQrCodeDialog="handleShowQrCode" />
		</div>
		<el-dialog
			:visible.sync="showQrCode"
			:lock-scroll="false"
			:show-close="false"
			custom-class="qrCode-dialog"
			>
			<img src="/imgStatic/qrCode.png" alt="">
			<div class="qrCode-dialog-tips">{{ $t("headerData.qrCodeTips") }}</div>
		</el-dialog>
	</div>
</template>

<script>
import Cookies from 'js-cookie';
import DevicePixelRatio from '@/lib/devicePixelRatio';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
	components: {
		Header,
		Footer,
	},
	data() {
		return {
			appClass: '',
			showQrCode: false,
			updateKey: false,
			pageScala: '',
		};
	},
	created() {
		this.webModeFunc();
		this.handleResize();
	},
	updated() {
		const cookieLang = Cookies.get('language') || 'zh';

		if (this.lang !== cookieLang) {
			this.$store.commit('setLang', cookieLang);
		}

		if (this.$route.name) {
			document.title = this.$t(`titleData.${this.$route.name}`);
		}
	},
	beforeMount() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		webModeFunc() {
			let webMode = 1;
			if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
				webMode = 2; // 移动端
			} else {
				webMode = 1; // PC端
				new DevicePixelRatio().init();
			}

			this.$store.commit('setWebMode', webMode);
			document.querySelector('body').className = `app-${webMode === 1 ? 'pc' : 'mobile'}`;
		},
		handleResize() {
			const { innerWidth } = window;
			const { webMode } = this.$store.state;

			// 以992px为界限，以上为pc模式，以下为H5模式
			if ((innerWidth >= 992 && webMode === 2) || (innerWidth < 992 && webMode === 1)) {
				this.$store.commit('setWebMode', innerWidth >= 992 ? 1 : 2);

				document.querySelector('body').className = `app-${innerWidth >= 992 ? 'pc' : 'mobile'}`;
			}

			const defalutPageWidth = 1920; // 设计默认尺寸

			let scale = 1;

			if (innerWidth > defalutPageWidth) {
				scale = innerWidth / defalutPageWidth;
			}
			const styleStr = `<style>.app-page-scala{transform: scale(${scale}) !important;}</style>`;
			this.pageScala = styleStr;

			this.$store.commit('setScala', scale);
		},
		handleShowQrCode() {
			this.showQrCode = true;
		},
	},
	computed: {
		loadingShow() {
			return this.$store.state.loading;
		},
		lang() {
			return this.$store.state.lang;
		},
	},
	watch: {
		lang: {
			immediate: true,
			handler(val, oldVal) {
				console.log(val, oldVal);
				this.updateKey = !this.updateKey; // 切换语言后强制重新渲染页面
				const lang = typeof val === 'string' ? val : Cookies.get('language') || 'zh';
				document.querySelector('html').lang = lang;
				document.querySelector('html').className = `app-${lang}`;

				if (this.$route.name) {
					document.title = this.$t(`titleData.${this.$route.name}`);
				}
			},
		},
    },
};
</script>

<style lang="scss">
body {
    margin: 0;
	padding-top: 89px;
	// word-wrap: break-word;
	// word-break: break-all;
	.page-content-box {
		width: calc(100% * .6375);
		min-width: 1224px;
		margin: 0 auto;
	}
	img{
		image-rendering: -moz-crisp-edges;
		image-rendering: -o-crisp-edges;
		image-rendering: -webkit-optimize-contrast;
		image-rendering: crisp-edges;
		-ms-interpolation-mode: nearest-neighbor;
	}
}
.html-data-box {
	p {
		margin: 0;
		word-wrap: break-word;
	}
}
.qrCode-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px !important;
    height: 450px !important;
    text-align: center;
    img {
      margin-top: -40px;
    }
    .qrCode-dialog-tips {
      font-size: 24px;
      color: #ABABAB;
    }
}
.app-mobile {
	overflow-x: hidden;
	.page-content-box {
		width: calc(100% * .855);
		min-width: calc(100% * .855);
		margin: 0 auto;
	}

	.el-dialog {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		margin: 0 !important;
	}

	.qrCode-dialog {
		width: 80% !important;
		height: auto !important;
	}
}
.app-zh {
	font-family: Noto Sans SC;
	input, textarea {
		font-family: Noto Sans SC;
	}
}
.app-en {
	font-family: calibri;
	input, textarea {
		font-family: calibri;
	}
}
.app-ja {
	font-family: Noto Sans JP;
	input, textarea {
		font-family: Noto Sans JP;
	}
	.app-pc {
		.qrCode-dialog {
			.qrCode-dialog-tips {
				padding: 0 30px !important;
				font-size: 24px !important;
				line-height: 28px !important;
			}
		}
	}
	.app-mobile {
		.qrCode-dialog {
			.qrCode-dialog-tips {
				padding: 0 10px !important;
				font-size: 24px !important;
				line-height: 28px !important;
			}
		}
	}
}
</style>
