/* eslint-disable*/
export default {
    config: {
        langZh: "中文",
        langEn: "English",
        langJa: "日本語",
    },
    titleData: {
        Home: "江森自控日立万宝压缩机（广州）有限公司",
        News: "新闻资讯",
        NewsDetail: "新闻资讯",
        Contract: "联系我们",
        About: "关于我们",
        Products: "产品展示",
    },
    headerData: {
        headerCompany: "江森自控日立万宝压缩机（广州）有限公司",
        headerCompanyMobile: "江森自控日立万宝压缩机",
        menuData: {
            home: '首 页',
            about: '关于我们',
            news: '新闻资讯',
            product: '产品展示',
            productSelect: '产品选型',
            contract: '联系我们',
        },
        qrCodeTips: "扫一扫，关注我们",
    },
    footerData: {
        navTitle: "底部导航",
        contractTitle: "联络方式",
        contractPhone: "电话：020-87861868",
        contractFax: "传真：020-87861808",
        contractWx: "微信：",
        addressTitle: "公司地址",
        addressContent: "地址：广东省广州市从化城鳌大道东路1086号 ",
        productLinkTitle: "全球产品链接",
        productLinkText: "江森自控日立压缩机",
        lawTitle: "法律声明",
        privacyTitle: "隐私条款",
        copyright1: '© COPYRIGHT 2022. ALL RIGHTS RESERVED.',
        copyright2: "江森自控日立万宝压缩机（广州）有限公司 版权所有",
        recordTxt: "粤ICP备2021111563号-2",
        policeRecordTxt: "公安网备：44011702000639",
    },
    pageDom: {
        moreBtn: '了解更多',
        checkMoreBtn: '查看更多',
        submitMessageBtn: '提交留言',
        checkNowBtn: '立即查看',
        downloadBtn: '下载',
        contractBtn: '联系我们',
    },
    homeData: {
        slogan: "cooling & heating",
        aboutIntroData: {
            title: '关于我们',
            content: '江森自控日立万宝压缩机（广州）有限公司（原广州日立压缩机有限公司）是由江森自控日立空调服务（香港）有限公司、广州万宝集团有限公司、台湾日立江森自控股份有限公司三方出资在广州从化建立的大型压缩机生产基地。始建于2003年9月。主要从事制冷与空调用涡旋压缩机的生产，经营范围主要包括涡旋压缩机的制造、销售、售后服务。现有在职员工900多人，具有90万台/年的产能。',
        },
        newsIntroData: {
            title: '新闻资讯',
            content: '了解更多有关新闻资讯的信息',
        },
        productIntroData: {
            title: '核心产品的应用领域',
            content: '我们的产品应用广泛，涉及多个应用领域；冷媒覆盖 R410A、R407C、R404A、R32、R134a、R1234yf、R454B、R513A、R448A 等。',
        },
        contractIntroData: {
            title: '联系我们',
            content1: '我们随时随地协助您走向成功！',
            content2: '获取更多产品及销售咨询，请联系我们。',
        }
    },
    newsData: {
        pageTitle: '全部资讯',
        allCategoryName: '所有',
    },
    newsDetailData: {
        moreNewsTitle: '更多新闻',
    },
    contractData: {
        messageTitle: '访客留言',
        messageTitleDesc: '请留言，我们将尽快与您联系。',
        agreementText1: '我已阅读和接受JCH相关的',
        agreementText2: '法律政策',
        agreementText3: '及',
        agreementText4: '隐私条款',
        agreementText5: '',
        surnamePlaceholder: '姓*',
        namePlaceholder: '名*',
        emailPlaceholder: '邮箱地址*',
        phonePlaceholder: '联系电话*',
        companyPlaceholder: '公司名称*',
        addressPlaceholder: '联系地址*',
        requirementDescPlaceholder: '需求描述',
        opinionPlaceholder: '意见',
        contractInfoTitle: '联系方式',
        addressContent: "地址：广东省广州市从化区城鳌大道东1086号 ",
        contractPhone: "电话：020-87861360",
        contractFax: "传真：020-87861358",
        contractWx: "微信：",
    },
    productsData: {
        productSelectData: {
            title: '日立涡旋压缩机在线选型',
            content: '在项目经理的日常工作中，选型工具是必不可少的。针对涡旋压缩机，一款基于云端维护更新的在线型“日立涡旋压缩机”选型软件——“Hitachi Compressor Selector”（网站提供三种语言的选择：中文、英文及日文）应运而生，旨在帮助客户轻松寻找并选择所需要的产品。',
            introIconTitle1: '全新工具',
            introIconTitle2: '涡旋压缩机',
            introIconTitle3: '体验升级',
            introIconTitle4: '在线版本',
        },
        productIntroData: {
            title: '核心产品的应用领域',
            content1: '我们的产品应用广泛，涉及多个应用领域：商用空调、模块机和热泵、热泵烘干机、数据中心和储能系统、运输空调、冷冻冷藏',
            content2: '冷媒覆盖 R410A、R407C、R404A、R32、R134a、R1234yf、R454B、R513A、R448A 等。',
        },
        productNameRule: {
            title: '产品规格型号命名规则',
        },
        productDoc: {
            title: '产品技术文档',
        },
        refrigerantType: '冷媒类型：',
    },
    aboutData: {
        introData: {
            title: '公司简介',
            content1: '江森自控日立万宝压缩机（广州）有限公司（原广州日立压缩机有限公司）是由江森自控日立空调服务（香港）有限公司、广州万宝集团有限公司、台湾日立江森自控股份有限公司三方出资在广州从化建立的大型压缩机生产基地。始建于2003年9月。主要从事制冷与空调用涡旋压缩机的生产，经营范围主要包括涡旋压缩机的制造、销售、售后服务。现有在职员工900多人，具有90万台/年的产能。',
            content2: '我们的产品应用广泛，涉及多个应用领域：商用空调、模块机和热泵、热泵烘干机、数据中心和储能系统、运输空调和冷冻冷藏。冷媒覆盖 R410A、R407C、R404A、R32、R134a、R1234yf、R454B、R513A、R448A 等。',
            content3: '我们的主要客户群为格力、海信、美的、海尔等国内知名空调企业，也包括日立集团、JCH在全世界的空调企业，以及印度、埃及、阿根廷等海内外客户。',
            content4: '我们有洞察力地汇聚技术，提供有针对性的解决方案，随时随地协助客户走向成功。以“价值观第一”为信条，恪守我们的解决方案，社会参与、商业运作方式和保护人与环境的承诺，担负起建设安全、舒适、可持续发展的世界的责任，和大家携手创造更加美好的明天。',
            content5: '我们的愿景：创造安全、舒适、可持续发展的世界。',
            content6: '我们的使命：随时随地协助客户走向成功。',
            content7: '我们的价值观：诚信第一、目的导向、客户驱动、关注未来，一个团队！',
        },
        marketingNetworkData: {
            title: '营销网络',
            customerTitle: '主要客户群体',
            customerContent1: '国内客户',
            customerContent2: '格力、海信日立、美的、海尔、麦克维尔、约克、特灵、开利、奥克斯、长虹、TCL、维克、芬尼克兹、申菱、欧科、英维克、佳力图、依米康、国祥、鼎汉、美乐柯等。',
            customerContent3: '海外客户',
            customerContent4: '在国外，我司在日本、中东、欧洲、北美、新加坡等地设有分支机构，除了江森自控国际和江森自控日立内部客户外，在江森自控国际政策容许范围内，面向全球客户提供产品以及服务。',
            saleContractTitle: '销售联系方式',
            saleContractContent1: '地址：广东省广州市从化区城鳌大道东1086号',
            saleContractContent2: '电话：020-87861360',
            saleContractContent3: '传真：020-87861358',
        },
        qualityData: {
            title: '品质保证',
            qualityTextTitle1: '机加工生产线',
            qualityTextContent1: '采用日本、台湾、美国德国等先进的高精度制造设备，保证每一个生产制造加工工序质量的稳定性，全面实现生产智能化，操作规范化。',
            qualityTextTitle2: '电机生产线',
            qualityTextContent2: '采用日本进口电机制造设备并辅于Cobot（协作机器人），实现定转子的自动化生产，稳定高效地保证产品质量及其一致性。',
            qualityTextTitle3: '管壳生产线',
            qualityTextContent3: '使用世界领先的储能式焊机及中频逆变焊机，生产稳定。使用KUKA机器人实现上下壳全自动化生产，极大地提高了质量稳定性及生产效率。随着Cobot（协作机器人）多工位的铺开，壳体自动化率进一步提高。',
            qualityTextTitle4: '组立生产线',
            qualityTextContent4: '采用日本进口的全套自动化组立生产线，通过在线同步检测，保证出厂的每台压缩机质量的均一性。',
            qualityTextTitle5: '过程检测',
            qualityTextContent5: '采用国际先进水平的三坐标测定仪、圆度仪和轮廓仪等精密仪器，用于抽样检查形状复杂的尺寸、形位公差和粗糙度等，精确度达到微米级，同时用于校正各种量具的形位误差，尺寸误差。',
            qualityTextTitle6: '成品检测',
            qualityTextContent6: '采用国际领先水平的性能、噪音和寿命检测设备，用于成品抽样检查和可靠性确认。',
            qualityIntroText: '江森自控日立万宝压缩机（广州）有限公司推行江森自控JCMS制造管理系统，秉承日立严格品质管理的理念，以“以顾客为中心，持续质量改进，管理技术创新，争创行业一流”为质量方针。全面推行建立并通过ISO9001质量管理、ISO14001环境管理、ISO45001职业健康安全管理三个体系为基础的综合管理体系认证。',
        },
        qualityCertificationData: {
            title: '质量认证',
            content: '江森自控日立万宝压缩机（广州）有限公司生产的压缩机进行了以下认证：CB认证、CE-LVD认证、CE-PED认证、CQC认证、CRAA认证和UL认证等。',
        },
        companyTableInfoData: {
            lineTitle1: '公司名称',
            lineContent1: '江森自控日立万宝压缩机（广州）有限公司',
            lineTitle2: '主要业务',
            lineContent2: '涡旋压缩机的研发、生产、销售、售后服务',
            lineTitle3: '注册资金',
            lineContent3: '1780万美元',
            lineTitle4: '成立日期',
            lineContent4: '2003年9月',
            lineTitle5: '地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址',
            lineContent5: '广东省广州市从化区城鳌大道东路1086号',
            lineTitle6: '员工人数',
            lineContent6: '900',
        }
    },
    testData: {
        homeData: {
            adList: {
                id1: {
                    title: 'Welcome',
					desc: '我们坐落在广州，是国内行业领先的日立涡旋压缩机的生产基地……',
                },
                id2: {
                    title: '我们的营销网络',
					desc: '我们的主要客户群遍布海内外（中国、印度、埃及、阿根廷等），客户为海内外知名企业。',
                },
                id3: {
                    title: '我们的产品',
					desc: '我们的产品应用广泛，涉及多个应用领域：商用空调、模块机和热泵、热泵烘干机、数据中心和储能系统、运输空调、冷冻冷藏',
                },
                id4: {
                    title: '我们的核心技术',
					desc: '我们的产品设计拥有多项技术专利：动态油平衡技术、优化非对称涡旋线、高转速特性……',
                },
            },
            newsList: {
                id1: {
                    title: 'JCH-GC斩获“极智奖”及“十大供应链产品”双奖',
					date: '2021年9月25日',
                },
                id2: {
                    title: '日立压缩机创新产品DD110上市',
					date: '2021年4月7日',
                },
                id3: {
                    title: 'JCH-GC产品斩获“创新产品”殊荣',
					date: '2021年4月5日',
                },
            },
            productList: {
                id1: {
                    category: '应用领域',
					name: '商用空调',
                },
                id2: {
                    category: '应用领域',
					name: '模块机和热泵',
                },
                id3: {
                    category: '应用领域',
					name: '热泵烘干机',
                },
                id4: {
                    category: '应用领域',
					name: '数据中心和储能系统',
                },
                id5: {
                    category: '应用领域',
					name: '运输空调',
                },
                id6: {
                    category: '应用领域',
					name: '冷冻冷藏',
                },
            },
        },
        productsData: {
            productDocList: {
                id1: {
                    title: '住宅或商用空调专用产品',
                },
                id2: {
                    title: '热泵专用产品',
                },
                id3: {
                    title: '运输空调专用产品',
                },
                id4: {
                    title: '冷冻冷藏专用产品',
                },
                id5: {
                    title: '机房空调专用产品',
                },
                id6: {
                    title: '热泵烘干专用产品',
                },
                id7: {
                    title: '储能专用产品',
                },
                id8: {
                    title: '变频器产品',
                }
            }
        },
        aboutData: {
            certificateList: {
                id1: {
                    title: 'ISO 9001 认证证书',
                },
                id2: {
                    title: 'ISO 14001 认证证书',
                },
                id3: {
                    title: 'ISO 45001 认证证书',
                },
                id4: {
                    title: 'PED M.D 证书',
                },
                id5: {
                    title: 'CQC 证书',
                },
                id6: {
                    title: 'CB 证书',
                },
                id7: {
                    title: 'UL 证书',
                },
                id8: {
                    title: 'CE-LVD 证书',
                },
                id9: {
                    title: 'CRAA 产品认证证书',
                },
                id10: {
                    title: 'UKCA 证书',
                },
                id11: {
                    title: 'EAC 证书',
                },
            }
        },
        newsData: {
            newsList: {
                id1: {
                    title: 'JCH-GC斩获“极智奖”及“十大供应链产品”双奖',
					date: '2021年9月25日',
                    desc: 'JCH-GC在中国暖通空调产业发展峰会暨中国“冷暖智造”颁奖盛典中斩获双奖',
                },
                id2: {
                    title: '日立压缩机创新产品DD110上市',
					date: '2021年4月7日',
                    desc: '日立压缩机创新产品DD110KHDG直流变频涡旋压缩机上市',
                },
                id3: {
                    title: 'JCH-GC产品斩获“创新产品”殊荣',
					date: '2021年10月5日',
                    desc: 'JCH-GC产品DD110KHDG-D1K2低温变频热泵涡旋压缩机以卓越的性能和可靠性斩获殊荣',
                },
                id4: {
                    title: 'JCH-GC获得海信家电“战略互信奖”最高奖项',
					date: '2020年12月18日',
                    desc: '“聚未来 创无限”海信家电核心供应商峰会，JCH-GC获得“战略互信奖”最高奖项',
                },
                id5: {
                    title: 'JCH-GC荣获美的暖通与楼宇事业部2020年“优秀供应商奖”',
					date: '2020年12月9日',
                    desc: '美的暖通与楼宇事业部在广东佛山顺德顺利召开了供应商大会，会上，江森自控日立万宝压缩机（广州）有限公司被授予《优秀供应商》奖项',
                },
                id6: {
                    title: 'JCH-GC获得海尔“创新引领奖”',
					date: '2020年11月15日',
                    desc: 'JCH-GC在海尔供应商年度评审中获得“创新引领奖”',
                },
            },
            categoryList: {
                id1: {
                    name: '所有',
                },
                id2: {
                    name: '业内声音',
                },
                id3: {
                    name: '公司动态',
                },
                id4: {
                    name: '市场活动',
                },
            },
        },
        newsDetailData: {
            newsDetailListData: {
                id1: {
                    title: 'JCH-GC斩获“极智奖”及“十大供应链产品”双奖',
                    date: '2021年9月25日',
                    detail: `
                        <p>JCH-GC斩获“极智奖”及“十大供应链产品”双奖——绿芯新启航，冷暖芯智造</p>
                        <p>2021年9月25日晚，中国暖通空调产业发展峰会暨中国“冷暖智造”颁奖盛典在杭州圆满落下帷幕。这次峰会以“冷暖智造·新启航”为主题，来自政府部门、产业链上下游企业的代表以及来自科研院所的专家学者、行业媒体等近人共聚一堂，见证了这一暖通行业盛事。</p>
                        <p>在这次中国“冷暖智造”颁奖盛典上，凭借自身的科技硬实力、创新驱动力的优秀表现斩获了<font style="color:#C3002F">“极智奖”</font>！同时低温变频热泵涡旋压缩机获得<font style="color:#C3002F">“十大供应链产品奖”</font>！</p>
                    `,
                },
                id2: {
                    title: '日立压缩机创新产品DD110上市',
                    date: '2021年4月7日',
                    detail: `
                        <p>2021年4月7日，日立压缩机创新产品DD110KHDG直流变频涡旋压缩机上市发布仪式在中国制冷展现场顺利举办。发布仪式上，日立首次向公众揭示了这一突破性产品，并展示了相关突破性技术成果。</p>
                        <p>中国制冷学会副理事长兼秘书长李晓虎，清华大学教授石文星，约克广州空调冷冻设备有限公司研发总监胡湘华， 江森自控日立万宝压缩机(广州)有限公司总经理郝志，江森自控日立万宝压缩机(广州)有限公司副总经理夏友辉等专家学者出席了本次发布会，并对日立创新技术的突破表示充分肯定。</p>
                        <p>“技术的日立”是日立品牌数百年发展至今的信条，从世界上第一台商用涡旋压缩机的推出，到如今全系列产品的多领域应用，日立压缩机始终秉承着技术驱动进步的理念，本次创新产品的发布，更充分表明了日立压缩机始终以实际行动推动行业技术的发展，不断推陈出新的决心。</p>
                        <p>（详见报道 https://mp.weixin.qq.com/s/nYFM4dOzkZAulfDfd8ATWw ）</p>
                    `,
                },
                id3: {
                    title: 'JCH-GC产品斩获“创新产品”殊荣',
                    date: '2020年10月5日',
                    detail: `
                        <p>2021中国制冷展“创新产品”共有来自52家企业的112件产品申报，产品类别涵盖家用及商用空调热泵设备、冷链设备、专用空调热泵设备、其他设备等四大类。经组委会形式审查，申报材料提交专家组评审。评审分为初审及终审两轮进行，共邀请36位（43人次）专家参与审查。</p>
                        <p>我司产品DD110KHDG-D1K2低温变频热泵涡旋压缩机以卓越的性能和可靠性，斩获“创新产品”殊荣。</p>
                    `,
                },
                id4: {
                    title: 'JCH-GC获得海信家电“战略互信奖”最高奖项',
                    date: '2020年12月18日',
                    detail: `
                        <p>2020年12月18日，海信家电开展了以“聚未来 创无限”为主题的全球核心供应商峰会，江森自控日立万宝压缩机（广州）有限公司凭借优秀的产品技术荣获海信家电最高奖项《战略互信奖》。未来江森自控日立万宝压缩机（广州）有限公司将与海信家电进行持续合作，共同研究探讨行业及市场的发展方向，努力创新，为社会提供更加节能高效的优秀产品。</p>
                    `,
                },
                id5: {
                    title: 'JCH-GC荣获美的暖通与楼宇事业部2020年“优秀供应商奖”',
                    date: '2020年12月9日',
                    detail: `
                        <p>2020年12月9日，美的暖通与楼宇事业部在广东佛山顺德顺利召开了供应商大会。会上，江森自控日立万宝压缩机（广州）有限公司被授予《优秀供应商》奖项。多年来，江森自控日立万宝压缩机（广州）有限公司与美的暖通多年来合作紧密，共同见证了一个个项目的成长。未来，江森自控日立万宝压缩机（广州）有限公司将与美的暖通携手同行，创造更多的辉煌。</p>
                    `,
                },
                id6: {
                    title: 'JCH-GC获得海尔“创新引领奖”',
                    date: '2021年11月15日',
                    detail: `
                        <p>2020年11月，海尔进行了供应商评审，江森自控日立万宝压缩机（广州）有限公司凭借过硬的产品实力荣获海尔最高奖项《创新引领奖》。很荣幸获得海尔的认可，一直以来，江森自控日立万宝压缩机（广州）有限公司坚定贯彻与海尔资源共享、优势互补、互惠共赢的理念。未来，江森自控日立万宝压缩机（广州）有限公司将与海尔共同推进行业产品能效水平，引领新发展。</p>
                    `,
                },
            }
        },
        codeProductData: {
            id1: {
                title: '商用空调',
                contentTitle: '多联机/单元机',
                content: '凭借多年可靠性设计经验,日立高效高转速变频涡旋压缩机可为空调系统提供能效超高，制冷（制热）能力更高和运行范围更宽的解决方案，且能符合节能减碳的趋势。',
                refrigerantContent: '冷媒类型：R32, R410A, R22',
                charact: {
                    id1: {
                        content: '高压腔直流变频喷气增焓涡旋压缩机',
                    },
                    id2: {
                        content: '高可靠性、高质量',
                    },
                    id3: {
                        content: '高效率，额定点(COP)优化，综合能效(APF)优化',
                    },
                    id4: {
                        content: '压缩机动态油平衡专利设计，实现自由多联',
                    },
                    id5: {
                        content: '噪音低、振动小',
                    },
                    id6: {
                        content: '优秀的喷气增焓设计，拓宽运行范围，极大增加低环温的制热量',
                    },
                    id7: {
                        content: '容积式油泵供油，优化供油机构，降低OCR（吐油率），提升压缩机可靠性和优化机组换热效率',
                    },
                    id8: {
                        content: '超宽转速设计，快速强劲制冷/制热，为用户提供更舒适环境',
                    },
                }
            },
            id2: {
                title: '模块机和热泵',
                contentTitle: '空调、采暖、热水',
                content: '拥有多项专利技术的日立直流变频喷气涡旋压缩机广泛应用于模块机和热泵系统，为人们创造理想、舒适、低碳、节能的生活空间。',
                refrigerantContent: '冷媒类型：R454B, R32, R410A',
                charact: {
                    id1: {
                        content: '高压腔直流变频喷气增焓涡旋压缩机',
                    },
                    id2: {
                        content: '高可靠性、高质量',
                    },
                    id3: {
                        content: '高效率，综合能效(APF/IPLV)优化',
                    },
                    id4: {
                        content: '噪音低、振动小',
                    },
                    id5: {
                        content: '优秀的喷气增焓设计，拓宽运行范围，极大增加低环温的制热量',
                    },
                    id6: {
                        content: '超高压比设计（高达14.3），保证低环温时制热的要求。比如机组在-35°C环境温度下，仍能安全可靠运行。',
                    },
                    id7: {
                        content: '超宽转速设计，快速强劲制冷/制热，为用户提供更舒适环境',
                    },
                    id8: {
                        content: '单压缩机可满足65KW机组',
                    },
                }
            },
            id3: {
                title: '热泵烘干机',
                contentTitle: '烘干',
                content: '日立独创具有超高烘干温度的大规格直流变频喷气涡旋压缩机为热泵烘干行业践行“减碳”作出卓越贡献。主要应用在工农业的高温热泵领域（包括粮食、农作物烘干，烟叶烘干），以及电镀、印刷等工艺高温热水和烘干除湿领域。',
                refrigerantContent: '冷媒类型：R410A, R134a',
                charact: {
                    id1: {
                        content: '高压腔直流变频喷气增焓涡旋压缩机',
                    },
                    id2: {
                        content: '高效、高可靠性、高质量',
                    },
                    id3: {
                        content: '噪音低、振动小',
                    },
                    id4: {
                        content: '优秀的喷气增焓设计，拓宽运行范围，低环温强劲制热，确保烘干效果不衰减',
                    },
                    id5: {
                        content: '变频设计，精准控温，提高烘干物品品质',
                    },
                    id6: {
                        content: '超高压比设计（高达19.8）满足全年气候条件下烘干要求。',
                    },
                    id7: {
                        content: '宽广运行范围，最大冷凝温度高达85℃，满足工农业烘干需求',
                    },
                }
            },
            id4: {
                title: '数据中心和储能系统',
                contentTitle: '机房，数据中心空调，储能空调',
                content: '日立直流变频涡旋压缩机满足高效机房、数据中心、储能空调节能、高可靠性的使用要求，并实现机组超高能效运行，为数据中心提供绿色高效、稳定可靠的解决方案。',
                refrigerantContent: '冷媒类型：R410A, R134a, R1234yf',
                charact: {
                    id1: {
                        content: '高压腔直流变频涡旋压缩机',
                    },
                    id2: {
                        content: '高效、高可靠性、高质量',
                    },
                    id3: {
                        content: '噪音低、振动小',
                    },
                    id4: {
                        content: '超宽压比运行范围（压比1.1-8.0），满足各季节条件下高效制冷运行要求',
                    },
                    id5: {
                        content: '容积式油泵供油，优化供油机构，降低OCR（吐油率），提升压缩机可靠性和优化机组换热效率',
                    },
                    id6: {
                        content: '吸排气管螺纹连接设计，无需焊接作业',
                    },
                    id7: {
                        content: 'IP67防护等级电气接线盒设计，使机组适应各种应用场合的高防护要求',
                    },
                    id8: {
                        content: '超宽转速，快速强劲制冷，为快速降低电池模组温度，延长电池使用寿命',
                    },
                }
            },
            id5: {
                title: '运输空调',
                contentTitle: '轨道车辆空调、客车空调',
                content: '专为运输空调设计的日立涡旋压缩机，具备高效、高可靠性等技术优势，安装高度（高度177mm）低以及轻量化的特点，适用于高铁、城轨、地铁、电动巴士等运输空调领域，广泛应用于全球超过30个国家和地区的众多项目中。',
                refrigerantContent: '冷媒类型：R513A, R410A, R407C, R134a',
                charact: {
                    id1: {
                        content: '高压腔直流变频涡旋压缩机',
                    },
                    id2: {
                        content: '高效、高可靠性、高质量',
                    },
                    id3: {
                        content: '噪音低、振动小',
                    },
                    id4: {
                        content: '紧凑的尺寸，轻量化，更省空间，更节能',
                    },
                    id5: {
                        content: 'IP67防护等级电气接线盒设计，使机组适应各种应用场合的高防护要求',
                    },
                }
            },
            id6: {
                title: '冷冻冷藏',
                contentTitle: '展示柜、冷库',
                content: '冷冻冷藏整体解决方案。日立高品质涡旋压缩机拥有更高的效率，卓越的耐用性和无与伦比的可靠性。为冷链实现从生产、运输、销售、储存等全过程低温保鲜提供解决方案。',
                refrigerantContent: '冷媒类型：R448A, R449A, R410A, R404A, R22',
                charact: {
                    id1: {
                        content: '高压腔直流变频喷液增焓涡旋压缩机',
                    },
                    id2: {
                        content: '高效、高可靠性、高质量',
                    },
                    id3: {
                        content: '压缩机动态油平衡专利设计，实现压缩机自由多联',
                    },
                    id4: {
                        content: '噪音低、振动小',
                    },
                    id5: {
                        content: '优秀的喷液增焓设计，拓宽运行范围，满足机组超低蒸发温度应用要求',
                    },
                    id6: {
                        content: '标配油面镜和排油管设计，实时监控压缩机运行油位及油品，便于运行维护',
                    },
                }
            }
        }
    },
};
