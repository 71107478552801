<template>
  <div>
    <div :class="iframeDialogClass" id="iframeDialogClass" v-drag>
      <div class="iframe-dialog-header">
        <div class="iframe-icon-dom">
          <img src="/imgStatic/iframeLogo.png" alt="" />
          Hitachi Compressor Selector
        </div>
        <div class="iframe-action-dom">
          <i class="el-icon-minus" @click="handleMinIframe"></i>
          <i class="el-icon-full-screen" @click="handleMaxIframe" v-show="webMode === 1"></i>
          <i class="el-icon-close" @click="handleCloseIframe"></i>
        </div>
      </div>
      <div class="iframe-content">
        <iframe id="iframeDialog" :src="iframePath" v-if="defaultIframeType !== 0" />
      </div>
    </div>
    <div class="iframe-tips-bar" v-show="showIframeType === 2" @click="handleNormalIframe">
      <div class="iframe-icon-dom">
        Hitachi Compressor Selector
      </div>
      <div class="iframe-action-dom">
        <i class="el-icon-full-screen" @click.stop="handleNormalIframe" v-show="webMode === 1"></i>
        <i class="el-icon-close" @click.stop="handleCloseIframe"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ProductSelect',
    props: {
        defaultIframeType: Number,
        iframePath: String,
    },
    components: {
    },
    data() {
      return {
        showIframeType: 0, // 0: 不显示；1: 正常小窗显示；2：最小化；3：最大化
      };
    },
    created() {
        this.showIframeType = this.defaultIframeType;
    },
    methods: {
      handleNormalIframe() {
        this.showIframeType = this.webMode === 1 ? 1 : 3; // 手机版只有最大化和最小化
        document.querySelector('#iframeDialogClass').style = '';
      },
      handleMinIframe() {
        this.showIframeType = 2;
        document.querySelector('#iframeDialogClass').style = '';
      },
      handleMaxIframe() {
        this.showIframeType = this.showIframeType === 3 ? 1 : 3;
        document.querySelector('#iframeDialogClass').style = '';
      },
      handleCloseIframe() {
        this.showIframeType = 0;
        document.querySelector('#iframeDialogClass').style = '';
        this.$emit('close');
      },
    },
    computed: {
      webMode() {
        return this.$store.state.webMode;
      },
      iframeDialogClass() {
        let className = '';
        switch (this.showIframeType) {
          case 0:
            className = 'new-iframe-dialog-min';
            break;
          case 1:
            className = 'new-iframe-dialog';
            break;
          case 2:
            className = 'new-iframe-dialog-min';
            break;
          case 3:
            className = 'new-iframe-dialog new-iframe-dialog-max';
            break;
          default:
            break;
        }

        return className;
      },
    },
    watch: {
      defaultIframeType(val, oldVal) {
          if (val !== oldVal && val === 1) {
              this.showIframeType = this.webMode === 1 ? val : 3; // h5显示只有最大化
          }
      },
    },
    directives: {
      drag: {
          // 指令的定义
          /*eslint-disable*/
          bind: (el) => {
              const odiv = el; // 获取当前元素
              el.onmousedown = (e) => {
                // 算出鼠标相对元素的位置
                const disX = e.clientX - odiv.offsetLeft;
                const disY = e.clientY - odiv.offsetTop;
                let left = '';
                let top = '';
                document.onmousemove = (eTmp) => {
                  // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                  left = eTmp.clientX - disX;
                  top = eTmp.clientY - disY;
                  // 绑定元素位置到positionX和positionY上面
                  // 移动当前元素
                  odiv.style.left = left + 'px';
                  odiv.style.top = top + 'px';
                };
                document.onmouseup = (eTmp) => {
                  document.onmousemove = null;
                  document.onmouseup = null;
                };
              };
          },
      },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /deep/.new-iframe-dialog {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    z-index: 10000;
    background: #fff;
    .iframe-dialog-header {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 30px;
      background: #f7f7f7;
      border-bottom: 1px solid #ededed;
      cursor: move;
      .iframe-icon-dom {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333333;
        img {
          width: 16px;
          margin-right: 15px;
        }
      }
      .iframe-action-dom {
        display: flex;
        align-items: center;
        margin-left: auto;
        i {
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
    .iframe-content {
      width: 100%;
      height: calc(100% - 40px);
      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
  /deep/.new-iframe-dialog-min {
    display: none;
  }
  /deep/.new-iframe-dialog-max {
    left: 0 !important;
    top: 0 !important;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
  }
  /deep/.iframe-tips-bar {
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 300px;
    height: 40px;
    padding: 0 10px;
    z-index: 1000;
    background: #C3002F;
    .iframe-icon-dom {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #FFFFFF;
      img {
        width: 16px;
        margin-right: 15px;
      }
    }
    .iframe-action-dom {
      display: flex;
      align-items: center;
      margin-left: auto;
      i {
        margin-left: 20px;
        cursor: pointer;
        color: #FFFFFF;
      }
    }
  }
</style>
<style lang="scss">
  .app-mobile {
    .iframe-content {
      overflow-x: scroll;
      overflow-y: hidden;
      iframe {
        width: 850px !important;
      }
    }
    .iframe-dialog-header {
      padding: 0 10px !important;
      .iframe-icon-dom {
        font-size: 10px !important;
        img {
          width: 15px;
          margin-right: 15px;
        }
      }
    }
    .iframe-tips-bar {
      width: 200px;
      height: 40px;
      .iframe-icon-dom {
        font-size: 10px;
        img {
          width: 10px;
          margin-right: 5px;
        }
      }
      .iframe-action-dom {
        display: flex;
        align-items: center;
        margin-left: auto;
        i {
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
  }
</style>
